import { ChangeEvent, useEffect, useState } from 'react';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import LoaderDot from '../../../../../../components/LoaderDot';
import NoteFormType from '../../../../../../types/NoteFormType';
import styles from './style.module.scss';

interface Props {
    note: NoteFormType;
    selectedNoteId?: string;
    selectNote: (note: NoteFormType) => void;
    setNoteTitle: (title: string) => void;
    saveNote: (param: void) => void;
}

function CaseNoteTab(props: Props) {
    const { note, selectNote, setNoteTitle, selectedNoteId, saveNote } = props;

    const [editTitle, setEditTitle] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        setTitle(note.title);
    }, [note.title]);

    const handleSelectNote = () => {
        selectNote(note);
        setEditTitle(false);
    };

    const handleEditTitleNote = (event: MouseEvent) => {
        event.preventDefault();
        setEditTitle(true);
    };
    const handleLeaveEditTitleNote = () => {
        if (note.id) {
            saveNote();
        }
        setEditTitle(false);
    };

    const handleChangeNoteTitle = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setTitle(value);
        setNoteTitle(value);
    };

    if (!note) {
        return <LoaderDot />;
    }

    return (
        <div className={styles.noteTab}>
            {editTitle && selectedNoteId === note.id ? (
                <Input
                    onChange={handleChangeNoteTitle}
                    value={title}
                    onBlur={handleLeaveEditTitleNote}
                />
            ) : (
                <Button
                    key={'btn' + note.id}
                    onClick={handleSelectNote}
                    onDoubleClick={handleEditTitleNote}
                    variant={
                        note.id === selectedNoteId ? 'primary' : 'secondary'
                    }
                >
                    {title}
                </Button>
            )}
        </div>
    );
}

export default CaseNoteTab;
