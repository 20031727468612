import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { fetchServices } from '../../../../services/serviceService';
import ServiceType from '../../../../types/ServiceType';
import ServiceTableRow from './serviceTableRow';
import styles from './style.module.scss';

const myServices: ServiceType[] = [
    {
        id: '9592ff35-a31e-41bb-8597-3586b997cef2',
        name: 'Consultation Cabinet',
        price: 345,
        duration: 60,
        tva: 20,
        isVisible: true,
    },
    {
        id: '4b10889b-9d44-4b71-8cbc-032aecbf9acc',
        name: 'Consultation Téléphonique',
        duration: 60,
        price: 250,
        tva: 20,
        isVisible: true,
    },
    {
        id: '6413308a-14ae-475c-bf8c-2bff8b3497ca',
        name: 'Consultation Video',
        duration: 60,
        price: 300,
        tva: 20,
        isVisible: true,
    },
    {
        id: '8f1f3cd8-6330-4b4b-bad1-b42d07cc94db',
        name: 'Examen Dossier',
        duration: 60,
        price: 100,
        tva: 20,
        isVisible: true,
    },
];

interface Props {
    setService: (param: ServiceType) => void;
    setModalSaveOpened: (param: boolean) => void;
    setModalRemoveOpened: (param: boolean) => void;
}

function ServiceTable(props: Props) {
    const { setService, setModalSaveOpened, setModalRemoveOpened } = props;

    const [services, setServices] = useState<ServiceType[]>(myServices);

    const handleServiceClick = (service: ServiceType) => {
        setService(service);
        setModalSaveOpened(true);
    };
    const handleDeleteServiceClick = (service: ServiceType) => {
        setService(service);
        setModalRemoveOpened(true);
    };

    const { isPending: servicesPending } = useQuery({
        queryKey: ['getServices'],
        queryFn: async () => {
            const { data } = await fetchServices();
            setServices(data);
            return data;
        },
    });

    if (servicesPending) {
        return <div>Chargement...</div>;
    }

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th scope="col">Service</th>
                    <th scope="col">Durée</th>
                    <th scope="col">Prix</th>
                    <th scope="col">TVA</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {services.map((myService) => (
                    <ServiceTableRow
                        key={myService.id}
                        service={myService}
                        serviceClick={handleServiceClick}
                        deleteServiceClick={handleDeleteServiceClick}
                    />
                ))}
            </tbody>
        </table>
    );
}

export default ServiceTable;
