import React, { ChangeEvent, useState } from 'react';
import { formElements } from '../../constants/formContants';
import OptionInterface from '../../interfaces/OptionInterface';
import FormElementType from '../../types/FormElementType';
import Button from '../Button';
import FaIcon from '../Icons/FaIcon';
import Input from '../Input';
import Radio from '../Radio';
import Select from '../Select/index';
import styles from './style.module.scss';

function FormEditor() {
    const data: FormElementType[] = [
        {
            Id: '1',
            Type: 'identity',
            Props: {
                name: 'identity',
            },
        },
        {
            Id: '2',
            Type: 'date',
            Props: {
                name: 'birthdate',
                label: 'date de naissance',
            },
        },
        {
            Id: '3',
            Type: 'email',
            Props: {
                name: 'email',
            },
        },
        {
            Id: '4',
            Type: 'phone',
            Props: {
                name: 'phone',
            },
        },
        {
            Id: '5',
            Type: 'address',
            Props: {
                name: 'address',
            },
        },
        {
            Id: '6',
            Type: 'input',
            Props: {
                isRequired: true,
                type: 'text',
                name: 'text',
                defaultValue: '',
                placeholder: 'text',
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    // console.log("change", event);
                },
                onClick: (event: React.MouseEvent) => {
                    // console.log("click", event);
                },
            },
        },
        {
            Id: '7',
            Type: 'textaera',
            Props: {
                isRequired: true,
                name: 'textaera',
                defaultValue: '',
                placeholder: 'textaera',
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    // console.log("change", event);
                },
                onClick: (event: React.MouseEvent) => {
                    // console.log("click", event);
                },
            },
        },
        {
            Id: '8',
            Type: 'select',
            Props: {
                isRequired: true,
                type: 'text',
                name: 'Select',
                options: [
                    { name: 'opt1', label: 'select option 1' },
                    { name: 'opt2', label: 'select options 2' },
                    { name: 'opt3', label: 'select option 3' },
                ],
                defaultValue: '',
                placeholder: 'Test 3',
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    // console.log("change", event);
                },
                onClick: (event: React.MouseEvent) => {
                    // console.log("click", event);
                },
            },
        },
        {
            Id: '9',
            Type: 'checkbox',
            Props: {
                isRequired: true,
                type: 'text',
                name: 'checkbox 3',
                options: [
                    { name: 'check 1', label: 'checkbox option 1' },
                    { name: 'check 2', label: 'checkbox option 2' },
                    { name: 'check 3', label: 'checkbox option 3' },
                ],
                defaultValue: '',
                placeholder: 'Test 3',
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    // console.log("change", event);
                },
                onClick: (event: React.MouseEvent) => {
                    // console.log("click", event);
                },
            },
        },
        {
            Id: '10',
            Type: 'radio',
            Props: {
                isRequired: true,
                type: 'text',
                name: 'radio',
                options: [
                    { name: 'radio1', label: 'radio option 1' },
                    { name: 'radio2', label: 'radio options 2' },
                    { name: 'radio3', label: 'radio option 3' },
                ],
                defaultValue: '',
                placeholder: 'Test 3',
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    // console.log("change", event);
                },
                onClick: (event: React.MouseEvent) => {
                    // console.log("click", event);
                },
            },
        },
    ];

    const [formData, setFormData] = useState(data);
    const [name, setName] = useState('');
    const [selectedElement, setSelectedElement] = useState('');
    const [showInputName, setShowInputName] = useState(false);
    const [showInputValues, setShowInputValues] = useState(false);
    const [option, setOption] = useState<OptionInterface>({
        name: '',
        label: '',
    });
    const [options, setOptions] = useState<OptionInterface[]>([]);
    const [requiredElement, setRequiredElement] = useState('Non');

    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setName(value);
    };

    const handleElementChange = (event: any) => {
        const { selectedOptions } = event.target;
        setSelectedElement(selectedOptions[0].value);
        const multipleValueOptions = ['select', 'radio', 'checkbox'];
        const nameNeededElements = [
            'input',
            'textaera',
            'date',
            'file',
            'select',
            'radio',
            'checkbox',
        ];
        setShowInputName(nameNeededElements.includes(selectedOptions[0].value));
        setShowInputValues(
            multipleValueOptions.includes(selectedOptions[0].value)
        );
    };

    const handleChangeValue = (event: any) => {
        const { value } = event.target;
        setOption(value);
    };

    const handleChangeRequired = (event: ChangeEvent<HTMLInputElement>) => {
        setRequiredElement(event.target.value);
    };

    const handleAddValue = (event: React.MouseEvent) => {
        if (option.name && option.name.length > 0) {
            setOptions([...options, option]);
        }
        setOption({ name: '', label: '' });
    };

    const handleRemoveValue = (value: OptionInterface) => {
        const indexValue = options.indexOf(value);
        const newOptions = [...options];
        newOptions.splice(indexValue, 1);
        setOptions(newOptions);
    };

    const handleAddClick = (event: React.MouseEvent) => {
        if (selectedElement.length === 0) {
            return;
        }
        const newElement: FormElementType = {
            Id: new Date().getMilliseconds.toString(),
            Type: selectedElement,
            Props: {
                name: name,
                label: name,
                isRequired: requiredElement === 'Oui',
            },
        };
        if (options.length > 0) {
            newElement.Props.options = options;
        }
        setFormData([...formData, newElement]);
        setOptions([]);
        setName('');
        setSelectedElement('');
        setRequiredElement('Non');
    };

    const handleRemoveElement = (element: any) => {
        const indexValue = formData.indexOf(element);
        const newFormData = [...formData];
        newFormData.splice(indexValue, 1);
        setFormData(newFormData);
    };

    // const handleSaveClick = (event: React.MouseEvent) => {
    //   console.log("formData =>", formData);
    // };

    return (
        <div className={styles.formEditor}>
            <div className={styles.actionContainer}>
                <h1>Créez votre formulaire</h1>
                <div className={styles.actionElementContainer}>
                    <div className={styles.inputContainer}>
                        <Select
                            name="selectElement"
                            label="Choisissez un élément"
                            placeholder="Choisir"
                            value={selectedElement}
                            options={Object.keys(formElements).map((x) => {
                                return { name: x, label: formElements[x].name };
                            })}
                            onChange={handleElementChange}
                        />
                    </div>
                    {showInputName && (
                        <div className={styles.inputContainer}>
                            <Input
                                onChange={handleChangeName}
                                label="Nom de l'élément"
                                placeholder="Nom de l'élément"
                                value={name}
                            />
                        </div>
                    )}
                    <div>
                        <Radio
                            checked={requiredElement}
                            onChange={handleChangeRequired}
                            options={[
                                { name: 'Oui', label: 'Oui' },
                                { name: 'Non', label: 'Non' },
                            ]}
                            name="required"
                            label="Obligatoire"
                        />
                    </div>
                </div>

                {showInputValues && (
                    <div>
                        <div className={styles.addValueContainer}>
                            <Input
                                onChange={handleChangeValue}
                                placeholder="valeur"
                                value={option.name}
                            />
                            <Button onClick={handleAddValue}>
                                <FaIcon>fa-solid fa-plus</FaIcon>
                            </Button>
                        </div>
                        <div className={styles.valuesContainer}>
                            {options.map((option: OptionInterface) => (
                                <div
                                    key={option.name}
                                    className={styles.valueContainer}
                                >
                                    <span>{option.label}</span>
                                    <Button
                                        onClick={() => {
                                            handleRemoveValue(option);
                                        }}
                                    >
                                        <FaIcon>fa-solid fa-xmark</FaIcon>
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div>
                    <Button
                        onClick={handleAddClick}
                        isDisabled={selectedElement.length === 0}
                    >
                        Ajouter un élément
                    </Button>
                </div>
            </div>
            <div className={styles.container}>
                {formData.map((element) => (
                    <div
                        className={styles.elementContainer}
                        key={element.Type + element.Props?.name}
                    >
                        <div className={styles.removeElement}>
                            <Button
                                variant="secondaryLight"
                                onClick={() => {
                                    handleRemoveElement(element);
                                }}
                            >
                                <FaIcon>fa-solid fa-xmark</FaIcon>
                            </Button>
                        </div>
                        {Element(element)}
                    </div>
                ))}
            </div>
        </div>
    );
}

function Element(element: FormElementType) {
    const type = element.Type;
    const FormElement = formElements[type].element;
    const props = element.Props;

    return <FormElement {...props} />;
}

export default FormEditor;
