import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import styles from './style.module.scss';

function Statistics() {
    const labels = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ];

    const dataBar = {
        labels,
        datasets: [
            {
                label: 'Droit de la famille',
                data: [13, 345, 54, 645, 2, 45, 76, 234, 24, 43, 56, 45],
                borderColor: 'red',
                backgroundColor: 'red',
            },
            {
                label: 'Droit des affaires',
                data: [12, 54, 654, 65, 86, 234, 654, 22, 56, 24, 66, 23],
                borderColor: 'orange',
                backgroundColor: 'orange',
            },
            {
                label: 'Droit pénal',
                data: [12, 54, 654, 65, 86, 234, 654, 22, 56, 24, 66, 23],
                borderColor: 'blue',
                backgroundColor: 'blue',
            },
        ],
    };

    const optionsBar = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    const dataLine = {
        labels,
        datasets: [
            {
                label: 'Dossier',
                data: [1, 1, 3, 5, 13, 44, 43, 4, 43, 1, 56, 54],
                borderColor: 'yellow',
                backgroundColor: 'yellow',
            },
            {
                label: 'Rendez-vous',
                data: [1, 4, 34, 1, 1, 3, 5, 8, 13, 44, 43, 4],
                borderColor: 'green',
                backgroundColor: 'green',
            },
            {
                label: 'Visites du profil',
                data: [1, 24, 50, 1, 1, 56, 5, 23, 56, 47, 49, 5],
                borderColor: 'gray',
                backgroundColor: 'gray',
            },
        ],
    };

    const optionsLine = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Spécialités',
            },
        },
    };

    const dataPie = {
        labels: ['Droit de la famille', 'Droit des affaires', 'Droit pénal'],
        // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
        datasets: [
            {
                label: 'Spécialités',
                data: [
                    13 + 345 + 54 + 645 + 2 + 45 + 76 + 234 + 24 + 43 + 56 + 45,
                    12 +
                        54 +
                        654 +
                        65 +
                        86 +
                        234 +
                        654 +
                        22 +
                        56 +
                        24 +
                        66 +
                        23,
                    12 +
                        54 +
                        654 +
                        65 +
                        86 +
                        234 +
                        654 +
                        22 +
                        56 +
                        24 +
                        66 +
                        23,
                ],
                // you can set indiviual colors for each bar
                backgroundColor: ['red', 'orange', 'blue'],
                borderWidth: 1,
            },
        ],
    };

    const dataPie2 = {
        labels: ['En cours', 'Terminé', 'Archivé', 'Refusé'],
        // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
        datasets: [
            {
                label: 'Dossiers',
                data: [
                    13 + 345 + 54 + 645 + 2 + 45 + 76 + 234 + 24 + 43 + 56 + 45,
                    12 +
                        54 +
                        654 +
                        65 +
                        86 +
                        234 +
                        654 +
                        22 +
                        56 +
                        24 +
                        66 +
                        23 +
                        65 +
                        86 +
                        234 +
                        654 +
                        22 +
                        56 +
                        24 +
                        66 +
                        23 +
                        12 +
                        54 +
                        654,
                    1545,
                    300,
                ],
                // you can set indiviual colors for each bar
                backgroundColor: ['orange', 'green', 'gray', 'red'],
                borderWidth: 1,
            },
        ],
    };
    ChartJS.register(
        ArcElement,
        Tooltip,
        Legend,
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        PointElement,
        LineElement
    );
    const today = new Date().toISOString();
    return (
        <div className={styles.stat}>
            <Card>
                <div className={styles.chartCardPie}>
                    <Doughnut data={dataPie} />
                </div>
                <div className={styles.interval}>
                    <Input type="date" label="Date de début" />
                    <Input type="date" label="Date de fin" value={today} />
                </div>
            </Card>

            <Card>
                <div className={styles.chartCardChart}>
                    <Bar data={dataBar} options={optionsBar} />
                </div>
                <div className={styles.interval}>
                    <Input type="date" label="Date de début" />
                    <Input type="date" label="Date de fin" value={today} />
                </div>
            </Card>
            <Card>
                <div className={styles.chartCardChart}>
                    <Bar data={dataBar} options={optionsBar} />
                </div>
                <div className={styles.interval}>
                    <Input type="date" label="Date de début" />
                    <Input type="date" label="Date de fin" value={today} />
                </div>
            </Card>
            <Card>
                <div className={styles.chartCardChart}>
                    <Line data={dataLine} options={optionsLine} />
                </div>
                <div className={styles.interval}>
                    <Input type="date" label="Date de début" />
                    <Input type="date" label="Date de fin" value={today} />
                </div>
            </Card>
            <Card>
                <div className={styles.chartCardPie}>
                    <Doughnut data={dataPie2} />
                </div>
                <div className={styles.interval}>
                    <Input type="date" label="Date de début" />
                    <Input type="date" label="Date de fin" value={today} />
                </div>
            </Card>
        </div>
    );
}

export default Statistics;
