import { ChangeEvent, useState } from 'react';
import Button from '../../components/Button';
import AddressForm from '../../components/FormEditor/AddressForm';
import DateForm from '../../components/FormEditor/DateForm';
import EmailForm from '../../components/FormEditor/EmailForm';
import IdentityForm from '../../components/FormEditor/IdentityForm';
import FaIcon from '../../components/Icons/FaIcon';
import Input from '../../components/Input';
import Radio from '../../components/Radio';
import Select from '../../components/Select';
import OptionInterface from '../../interfaces/OptionInterface';
import styles from './style.module.scss';

function InformationForm() {
    const radioOptions: OptionInterface[] = [
        {
            label: 'Marié(e)',
            name: 'maried',
        },
        {
            label: 'Concubin',
            name: 'cohabitant',
        },
        {
            label: 'Célibataire',
            name: 'single',
        },
    ];

    const typeAffaire: OptionInterface[] = [
        {
            id: '1',
            name: '1',
            label: 'Pénal',
            value: `Contrat de travail ; bulletins de salaires ; Avis
          d’imposition ; Certificats médicaux ; Ordonnances ; Résultats
          d’examens ; Quittances de frais acquittés ; Attestations de tiers`,
        },
        {
            id: '2',
            name: '2',
            label: 'Nationalité',
            value: `Acte de naissance du client ; Jugements rectificatifs
          d’acte de naissance du client ; Actes de naissance des parents du
          client, Justificatifs de nationalité des parents, Pièce d’identité des
          parents, Actes de mariage des parents, Acte de reconnaissance`,
        },
        {
            id: '3',
            name: '3',
            label: 'Séjour',
            value: `Date d’entrée en France; Passeport ; Visas d’entrée ; preuves
          de présence en France ; Livret familial ; Actes de naissance des
          enfants du client ; Certificats de scolarité des enfants du client ;
          Bulletins de salaires ; Avis d’imposition ; Attestations de tiers`,
        },
        {
            id: '4',
            name: '4',
            label: 'Réparation du préjudice corporel',
            value: `Certificats médicaux ; Ordonnances ;
          Résultats d’examens ; Quittances de frais acquittés ; bulletins de
          salaires ; Avis d’imposition ; arrêts de travail `,
        },
        {
            id: '5',
            name: '5',
            label: 'Licenciement',
            value: `Lettre de convocation à l’entretien préalable ; lettre de
          licenciement ; Contrat de travail ; Bulletins de salaires ; Avis
          d’imposition ; Attestations de tiers`,
        },
    ];

    const [doc, setDoc] = useState('');
    const handleChangeType = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const docs = typeAffaire.find((item) => {
            return item.id === value;
        });
        setDoc(docs?.value || '');
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Formulaire d'Informations</h1>
            <p className={styles.summary}>
                Ce formulaire est destiné à facilité le contact avec votre
                avocat.
            </p>
            <div className={styles.body}>
                <div className={styles.bodyInfo}>
                    <IdentityForm />
                    <Input label="Profession" />
                    <div className={styles.familyInfo}>
                        <Radio
                            name={'familySituation'}
                            label="Situation familiale"
                            options={radioOptions}
                        />
                        <Input type="number" label="Nombre d'enfants" />
                    </div>
                    <div className={styles.phone}>
                        <Input
                            label="Numéro de téléphone personnel"
                            max={10}
                            min={10}
                        />
                        <Input
                            label="Numéro de téléphone professionel"
                            max={10}
                            min={10}
                        />
                    </div>
                    <EmailForm />
                    <AddressForm />
                    <DateForm label="Date de naissance" name="birthdate" />

                    <Select
                        options={typeAffaire}
                        label="Type d'affaire"
                        name="caseType"
                        onChange={handleChangeType}
                    />
                    {doc && (
                        <div>
                            <h4>Veuillez fournir les documents suivants:</h4>
                            <p>{doc}</p>
                        </div>
                    )}
                    <div className={styles.btn}>
                        <Button>
                            <FaIcon>fa-save</FaIcon>Enregistrer
                        </Button>
                        <Button>
                            <FaIcon>fa-print</FaIcon> Imprimer
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InformationForm;
