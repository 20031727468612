import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAlert } from '../../../../AlertProvider';
import AddressForm from '../../../../components/FormEditor/AddressForm';
import DateForm from '../../../../components/FormEditor/DateForm';
import EmailForm from '../../../../components/FormEditor/EmailForm';
import IdentityForm from '../../../../components/FormEditor/IdentityForm';
import PhoneForm from '../../../../components/FormEditor/PhoneForm';
import SearchBySpeciality from '../../../../components/SearchBar/SearchBySpeciality';
import Textaera from '../../../../components/Textaera';
import { alertType } from '../../../../constants/alertTypeConstant';
import { successStatus } from '../../../../constants/requestStatusContants';
import CreationCaseInterface from '../../../../interfaces/CreationCaseInterface';
import NavDataInterface from '../../../../interfaces/NavDataInterface';
import specialityType from '../../../../types/SpecialityType';
import styles from './style.module.scss';

interface Props {
    setToolbar: (param: NavDataInterface[]) => unknown;
    setTypeAction: (param: null) => unknown;
    request: (
        data: CreationCaseInterface
    ) => Promise<AxiosResponse<unknown, unknown>>;
}

function CaseForm(props: Props) {
    const { setToolbar, setTypeAction, request } = props;
    const alert = useAlert();

    const [clientCase, setClientCase] = useState<{ [key: string]: string }>({});

    const { mutate } = useMutation({
        mutationFn: async (clientCase: CreationCaseInterface) => {
            if (clientCase) {
                const { data, status } = await request(clientCase);

                if (successStatus.includes(status)) {
                    alert.show({
                        children: 'Le dossier a été créé avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    useEffect(() => {
        const handleClose = () => {
            setTypeAction(null);
        };
        const handleSaveNewCase = () => {
            const newClientCase: CreationCaseInterface = {
                civility: clientCase['civility'],
                city: clientCase['city'],
                country: clientCase['country'],
                description: clientCase['description'],
                email: clientCase['email'],
                firstName: clientCase['firstName'],
                lastName: clientCase['lastName'],
                phone: clientCase['phone'],
                streetName: clientCase['streetName'],
                zipCode: clientCase['zipCode'],
                specialityId: clientCase['specialityId'],
                birthDate: clientCase['birthDate'],
            };
            mutate(newClientCase);
        };

        const newCaseToolbar: NavDataInterface[] = [
            {
                id: 'newCaseToolbar1',
                icon: 'fa-save',
                label: 'Enregistrer',
                onClick: handleSaveNewCase,
            },
            {
                id: 'newCaseToolbar2',
                icon: 'fa-arrow-up-right-from-square',
                label: 'Publier',
            },
            {
                id: 'newCaseToolbar3',
                icon: 'fa-remove',
                label: 'Annuler',
                onClick: handleClose,
                position: 'end',
            },
        ];
        setToolbar(newCaseToolbar);
    }, [clientCase, mutate, setToolbar, setTypeAction]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        const newData = clientCase;
        newData[name] = value;
        setClientCase(newData);
    };

    const handleOnSpecialityChange = (speciality: specialityType | null) => {
        const newData = clientCase;
        newData['specialityId'] = speciality?.id || '';
        setClientCase(newData);
    };

    return (
        <div className={styles.body}>
            <SearchBySpeciality onOptionSelected={handleOnSpecialityChange} />
            <Textaera
                label="Description"
                name="description"
                onChange={handleOnChange}
                value={clientCase.description}
            />
            <IdentityForm onChange={handleOnChange} data={clientCase} />
            <DateForm
                onChange={handleOnChange}
                name={'birthDate'}
                label="Date de naissance"
                value={clientCase?.birthDate}
            />
            <AddressForm onChange={handleOnChange} data={clientCase} />
            <EmailForm onChange={handleOnChange} data={clientCase} />
            <PhoneForm onChange={handleOnChange} value={clientCase.phone} />
        </div>
    );
}

export default CaseForm;
