import StepInterface from '../../interfaces/StepInterface';
import Button from '../Button';
import styles from './style.module.scss';

interface Props {
    steps: StepInterface[];
}
export function Stepper(props: Props) {
    const { steps } = props;

    const listSteps = steps.sort((a, b) => {
        return a.date.getMilliseconds() - b.date.getMilliseconds();
    });

    return (
        <div className={styles.progressContainer}>
            {listSteps.map((step) => (
                <div key={step.id} className={styles.progressBar}>
                    <div className={styles.dot}></div>
                    <div className={styles.step}>
                        <label className={styles.date}>
                            {step.date.toLocaleString()}
                        </label>
                        <label className={styles.label}>
                            <Button
                                variant="secondaryLight"
                                onClick={(e) => {
                                    if (step.onClick) {
                                        step.onClick({ e, data: step });
                                    }
                                }}
                            >
                                {step.label}
                            </Button>
                        </label>
                    </div>
                    <div className={styles.bar}></div>
                </div>
            ))}
        </div>
    );
}
