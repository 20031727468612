import { ChangeEvent, FormEvent, useState } from 'react';
import { useAuth } from '../../../AuthProvider';
import Button from '../../../components/Button';
import ButtonLink from '../../../components/ButtonLink';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import { forgotPage } from '../../../constants/pagesRoutesContants';
import { validationFunctions } from '../../../constants/validationConstants';
import styles from './style.module.scss';

function Login() {
    const { userLogin } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const setValueOnChange: any = {
        email: setEmail,
        password: setPassword,
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (email.length === 0 || password.length === 0) {
            return;
        }
        userLogin({
            email: email,
            password: password,
        });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setValueOnChange[name](value);
    };

    const emailError = validationFunctions.email(email);
    const passwordError = validationFunctions.loginPassword(password);

    return (
        <Card>
            <form onSubmit={handleSubmit} className={styles.container}>
                <h2>Connexion</h2>
                <Input
                    label="Email"
                    name="email"
                    icon="mail"
                    onChange={handleChange}
                    error={emailError.errorMessage}
                />
                <Input
                    type="password"
                    label="Mot de passe"
                    name="password"
                    icon="password"
                    onChange={handleChange}
                    error={passwordError.errorMessage}
                />
                <div className={styles.action}>
                    <ButtonLink link={forgotPage.path} variant="secondaryLight">
                        {forgotPage.name}
                    </ButtonLink>
                    <Button
                        type="submit"
                        isDisabled={
                            !emailError.isValid || !passwordError.isValid
                        }
                    >
                        Se connecter
                    </Button>
                </div>
            </form>
        </Card>
    );
}

export default Login;
