import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormEditor from '../../../components/FormEditor';
import SideNav from '../../../components/SideNav';
import Toolbar from '../../../components/Toolbar';
import NavDataInterface from '../../../interfaces/NavDataInterface';
import FormType from '../../../types/FormType';
import styles from './style.module.scss';

function FormsEditor() {
    const param = useParams();

    const [sticky, setSticky] = useState(false);
    const lawyerForms: FormType[] = [
        {
            id: 'id1',
            name: 'Form 1',
            creationDate: new Date(),
        },
        {
            id: 'id2',
            name: 'Form 2',
            creationDate: new Date(),
        },
        {
            id: 'id3',
            name: 'Form 3',
            creationDate: new Date(),
        },
    ];

    const handleOnCaseClick = (value: any) => {
        setSelectedItem(value);
        setSticky(true);
    };

    const [forms, setForms] = useState(lawyerForms);

    const handleSearchForm = (event: ChangeEvent<HTMLInputElement>) => {
        const { value = '' } = event.target;
        const searchedCase = lawyerForms.filter((x) => {
            return x.name
                .toLocaleLowerCase()
                .includes(value.toLocaleLowerCase());
        });
        setForms(searchedCase);
    };

    const navData: NavDataInterface[] = forms.map((form): NavDataInterface => {
        return {
            id: form.id,
            label: form.name,
            onClick: handleOnCaseClick,
            variant: 'tertiary',
            icon: 'fa-file-lines',
            iconColor: 'gray',
        };
    });

    const [selectedItem, setSelectedItem] = useState();
    const toolbar: NavDataInterface[] = [
        {
            id: 'toolbarBtn1',
            icon: 'fa-add',
            label: 'Nouveau formulaire',
        },
        {
            id: 'toolbarBtn2',
            icon: 'fa-save',
            label: 'Enregistrer',
        },
        {
            id: 'toolbarBtn3',
            icon: 'fa-star',
            label: (true ? 'Ajouter aux' : 'Retirer des') + 'favoris',
        },

        {
            id: 'toolbarBtn5',
            icon: 'fa-share',
            label: 'Envoyer',
        },
        {
            id: 'toolbarBtn4',
            icon: 'fa-trash',
            label: 'Supprimer',
            position: 'end',
        },
        {
            id: 'toolbarBtn0',
            icon: 'fa-remove',
            label: 'Annuler',
            position: 'end',
        },
    ];

    return (
        <div className={styles.container}>
            <SideNav
                selected={param.formId || ''}
                navData={navData}
                className={styles.sideNav}
                extendable={selectedItem}
                hasSearchBar={true}
                searchBarLabel="Formulaires :"
                handleSearchChange={handleSearchForm}
            />
            <div className={styles.body}>
                <Toolbar toolbarData={toolbar} isSticky={sticky} />
                {selectedItem && <FormEditor />}
            </div>
        </div>
    );
}

export default FormsEditor;
