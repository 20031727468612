import { ReactNode } from 'react';
import { classNames } from '../../helpers/classNames';
import Button from '../Button';
import FaIcon from '../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    children: ReactNode;
    data?: any;
    className?: any;
    onDelete?: (event: MouseEvent, data: any) => any;
}

function Chip(props: Props) {
    const { className, children, onDelete, data } = props;
    const deleteChip = (event: MouseEvent) => {
        if (onDelete) {
            onDelete(event, data);
        }
    };
    return (
        <div className={classNames([styles.chip, className])}>
            <span>{children}</span>
            {onDelete && (
                <Button variant="primaryLight" onClick={deleteChip}>
                    <FaIcon>fa-remove</FaIcon>
                </Button>
            )}
        </div>
    );
}

export default Chip;
