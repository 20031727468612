import { availabilityEndpoint as endpoint } from '../constants/endpointsContants';
import AvailabilityType from '../types/AvailabilityType';
import request from './genericRequestService';

async function fetchAvailabilities() {
    return await request.get({ endpoint });
}
async function fetchLawyerAvailabilities(id: string) {
    return await request.get({ endpoint, id });
}

async function addAvailabilities(data: AvailabilityType[]) {
    return await request.post({ endpoint, data });
}

async function deleteAvailabilities(data: AvailabilityType) {
    return await request.delete({ endpoint, id: data.id });
}

async function updateAvailabilities(data: AvailabilityType) {
    return await request.put({ endpoint, data });
}

export {
    addAvailabilities,
    deleteAvailabilities,
    fetchAvailabilities,
    fetchLawyerAvailabilities,
    updateAvailabilities,
};
