import React, { useState } from 'react';
import { percentage } from '../../helpers/number';
import OptionInterface from '../../interfaces/OptionInterface';
import Input from '../Input';
import styles from './style.module.scss';

function PricingSimulator() {
    const [months, setMonths] = useState(1);
    const [meetingCount, setMeetingCount] = useState(5);
    const [meetingPrice, setMeetingPrice] = useState(500);
    const [total, setTotal] = useState<{ [key: string]: any } | null>(null);

    const plans: { [key: string]: any } = {
        basic: { label: 'Basique', price: 14.99, percentage: 10 },
        advance: { label: 'Avancée', price: 49.99, percentage: 3 },
        premium: { label: 'Premium', price: 124.99, percentage: 0 },
    };

    const planOptions: OptionInterface[] = Object.keys(plans).map(
        (key: any) => {
            const plan = plans[key];
            return {
                name: key,
                label: plan.label,
                value: plan.name,
                data: plan,
            };
        }
    );

    const handleMonthsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const count = parseInt(value, 10);
        setMonths(count);
        calculateTotal(count, meetingCount, meetingPrice);
    };
    const handleMeetingCountChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;
        const count = parseInt(value, 10);
        setMeetingCount(count);
        calculateTotal(months, count, meetingPrice);
    };
    const handleMeetingPriceChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;
        const count = parseInt(value, 10);
        setMeetingPrice(count);
    };

    const calculateTotal = (
        months: number = 0,
        meetingCount: number = 0,
        meetingPrice: number = 0
    ) => {
        const planSimulator: { [key: string]: any } = {};
        planOptions.forEach((plan: OptionInterface) => {
            const selectedPlan = plan.data;
            const total =
                selectedPlan?.price * months +
                percentage(meetingPrice, selectedPlan?.percentage) *
                    meetingCount;
            planSimulator[plan.name] = total;
        });

        setTotal(planSimulator);
    };

    return (
        <div className={styles.pricingSimulator}>
            <h2>Simulateur de Prix</h2>
            <div className={styles.simulatorControls}>
                <Input
                    label="Nombre de mois :"
                    min={1}
                    type="number"
                    value={months}
                    onChange={handleMonthsChange}
                />
                <Input
                    label="Nombre de rendez-vous :"
                    min={1}
                    type="number"
                    value={meetingCount}
                    onChange={handleMeetingCountChange}
                />
                <Input
                    label="Prix du rendez-vous :"
                    min={1}
                    type="number"
                    value={meetingPrice}
                    onChange={handleMeetingPriceChange}
                />
            </div>
            <div className={styles.gain}>
                Gain : {meetingCount * meetingPrice}€
            </div>
            <div className={styles.containerTotalCost}>
                {planOptions.map((plan: any) => (
                    <div className={styles.totalCost}>
                        <p>Offre {plan.label}</p>
                        <p>
                            Charges liées au site :{' '}
                            {total?.[plan.name]?.toFixed(2)}€
                        </p>
                        <p>
                            Gain Total :
                            {(
                                meetingCount * meetingPrice -
                                total?.[plan.name]
                            )?.toFixed(2)}
                            €
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PricingSimulator;
