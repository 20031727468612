
import styles from "./style.module.scss";
import { classNames } from "../../../helpers/classNames";
import FileType from "../../../types/FileType";
import FileItem from "../file-item";
import { useEffect } from "react";

interface Props {
  files: FileType[];
  setFolder: (folder: FileType, setNavigationHistory:boolean) => void;
  setFolderCount: (value: number) => void;
}

function FileExplorerBody (props:Props){
  const { files, setFolder, setFolderCount } = props;
  useEffect(() => {
    setFolderCount(files.length);
  }, [setFolderCount, files]);

  const onDblClick = (file: FileType): any => {
    const isFolder = file.Extension === "folder";
    setFolder(file, isFolder);
  };

  return (
    <div className={classNames([styles.feBody])}>
      {files.map((item: FileType) => (
        <FileItem
          key={item.Id}
          fileFormat={item.Extension}
          onDblClick={()=>{onDblClick(item)}}
          id={item.Id}
        >
          {item.Name}
        </FileItem>
      ))}
    </div>
  );
};

export default FileExplorerBody;
