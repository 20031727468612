import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import styles from './style.module.scss';

import 'react-quill/dist/quill.snow.css';

interface Props {
    onChange?: (event: any) => void;
    value?: string;
}

function TextEditor(props: Props) {
    const { onChange, value = '' } = props;
    // const myColors = [
    //   "red",
    //   "blue",
    //   "green",
    //   "purple",
    //   "#785412",
    //   "#452632",
    //   "#856325",
    //   "#963254",
    //   "#254563",
    //   "white",
    // ];
    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            [{ align: ['right', 'center', 'justify'] }],
            ['link', 'image', 'video'],
        ],
    };

    const formats = [
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'background',
        'script',
        'header',
        'blockquote',
        'code-block',
        'indent',
        'list',
        'direction',
        'align',
        'link',
        'image',
        'video',
        'formula',
    ];

    const [code, setCode] = useState('');
    const handleProcedureContentChange = (content: any) => {
        setCode(content);
        if (onChange) {
            onChange(content);
        }
    };

    useEffect(() => {
        setCode(value);
    }, [value]);

    return (
        <ReactQuill
            className={styles.text}
            theme="snow"
            modules={modules}
            formats={formats}
            value={code}
            onChange={handleProcedureContentChange}
        />
    );
}

export default TextEditor;
