import { useEffect } from 'react';
import SearchInput from '../../../../../components/SearchInput';
import NavDataInterface from '../../../../../interfaces/NavDataInterface';
import styles from '../style.module.scss';

interface Props {
    setToolbar: (param: NavDataInterface[]) => any;
    setTypeAction: (param: 'view_case') => any;
}

function SearchForCase(props: Props) {
    const { setToolbar, setTypeAction } = props;

    useEffect(() => {
        const handleClose = () => {
            setTypeAction('view_case');
        };
        const toolbar: NavDataInterface[] = [
            {
                id: 'searchToolbar1',
                icon: 'fa-remove',
                label: 'Annuler',
                onClick: handleClose,
                position: 'end',
            },
        ];

        setToolbar(toolbar);
    }, [setToolbar, setTypeAction]);

    return (
        <div className={styles.container}>
            <div className={styles.aj}>
                <SearchInput placeholder="Articles ou Jurisprudences" />
            </div>
            <div className={styles.body}></div>
        </div>
    );
}

export default SearchForCase;
