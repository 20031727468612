import { useQuery } from '@tanstack/react-query';
import { Icon } from 'leaflet';
import { ChangeEvent, useState } from 'react';
import location from '../../assets/location.svg';
import Map from '../../components/Map';
import SearchBar from '../../components/SearchBar';
import { storageItems } from '../../constants/storageItemsContants';
import { classNames } from '../../helpers/classNames';
import MapDataInterface from '../../interfaces/MapDataInterface';
import { fetchLawfirms } from '../../services/lawfirmService';
import LawfirmType from '../../types/LawfirmType';
import LawfirmCard from './lawfirmCard';
import LawfirmPopup from './lawfirmPopup';
import styles from './style.module.scss';

function Lawfirms() {
    const speciality = localStorage.getItem(storageItems.speciality);
    const [params, setParams] = useState<{ [key: string]: any }>({
        speciality: speciality,
    });
    const [lawfirmsList, setLawfirmsList] = useState<LawfirmType[]>([]);

    const { isPending: isPendingLawfirms } = useQuery({
        queryKey: ['lawfirm', params],
        queryFn: async () => {
            try {
                const { data } = await fetchLawfirms(params);
                setLawfirmsList(data || []);
            } catch (error) {}
            return [];
        },
    });

    const lawfirmIcon = new Icon({
        iconUrl: location,
        iconSize: [32, 32],
    });

    if (isPendingLawfirms && lawfirmsList.length < 1) {
        return <div>Loading...</div>;
    }

    const mapData: MapDataInterface[] = lawfirmsList.map(
        (lawfirm: LawfirmType) => {
            return {
                id: lawfirm.id,
                label: lawfirm.name,
                latitude: lawfirm.latitude,
                longitude: lawfirm.longitude,
                link: `#${lawfirm.id}`,
                icon: lawfirmIcon,
                popup: <LawfirmPopup lawfirm={lawfirm} />,
            };
        }
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (value.length === 0) {
            const newParams = { ...params };
            newParams[name] = null;
            setParams(newParams);
        }
    };
    const userLocation = {
        address: params.address,
        latitude: params.latitude,
        longitude: params.longitude,
    };

    return (
        <div className={classNames([styles.lawfirms])}>
            <div className={styles.map}>
                <Map data={mapData} userLocation={userLocation} />
            </div>
            <div className={styles.searchBarContainer}>
                <SearchBar
                    setParams={setParams}
                    params={params}
                    onChange={handleChange}
                />
            </div>
            {lawfirmsList.map((lawfirm: LawfirmType) => (
                <LawfirmCard key={lawfirm.id} lawfirm={lawfirm} />
            ))}
        </div>
    );
}

export default Lawfirms;
