import { locationEndpoint as endpoint } from '../constants/endpointsContants';
import request from './genericRequestService';

interface CoordinatesParam {
    latitude: number;
    longitude: number;
}

async function fetchAddress(address: string) {
    return request.get({
        endpoint: endpoint + '/address',
        params: { address: address },
    });
}

async function fetchCoordinates(coordinates: CoordinatesParam) {
    return request.get({
        endpoint: endpoint + '/coordinates',
        params: coordinates,
    });
}

export { fetchAddress, fetchCoordinates };
