// import { useQuery } from "@tanstack/react-query";
// import {
//   fetchCaseTimeSpent,
//   fetchClientCases,
// } from "../../../services/caseService";
import styles from './style.module.scss';
// import { useParams } from "react-router-dom";
import { useState } from 'react';
import Autocomplete from '../../../components/Autocomplete';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
// import CaseType from "../../../types/CaseType";
import Card from '../../../components/Card';
import Checkbox from '../../../components/Checkbox';
import OptionInterface from '../../../interfaces/OptionInterface';
import Bill from './bill';

function Billing() {
    // const param = useParams();
    // const [userCase, setUserCase] = useState<CaseType | null>(null);

    // const { data: clientsCases } = useQuery({
    //   queryKey: ["getClientsCases"],
    //   queryFn: async () => {
    //     const { data } = await fetchClientCases();
    //     return data;
    //   },
    // });

    // const { data: billingData } = useQuery({
    //   queryKey: ["generateBilling", userCase],
    //   queryFn: async () => {
    //     if (userCase) {
    //       const { data } = await fetchCaseTimeSpent(userCase?.id);
    //       return data;
    //     }
    //     return null;
    //   },
    // });

    const optionsDossiers: OptionInterface[] = [
        {
            label: 'Dossier XDFFEFF',
            name: '123456789',
        },
        { label: 'Dossier XD344FF', name: '234567891' },
    ];
    const optionsServices: OptionInterface[] = [
        {
            label: 'Consultation 12/05/2024',
            name: 'consultation1',
            data: {
                prixEuro: 432,
                dureeMin: 60,
                date: '12/05/2024',
            },
        },
        {
            label: 'Consultation 13/05/2024',
            name: 'consultation2',
            data: {
                prixEuro: 432,
                dureeMin: 120,
                date: '13/05/2024',
            },
        },
        {
            label: 'Consultation Téléphonique 14/05/2024',
            name: 'consultation3',
            data: {
                prixEuro: 135,
                dureeMin: 20,
                date: '14/05/2024',
            },
        },
        {
            label: 'Consultation Téléphonique 15/05/2024',
            name: 'consultation4',
            data: {
                prixEuro: 135,
                dureeMin: 60,
                date: '15/05/2024',
            },
        },
        {
            label: 'Consultation 12/05/2024',
            name: 'consultation5',
            data: {
                prixEuro: 500,
                dureeMin: 60,
                date: '12/05/2024',
            },
        },
        {
            label: 'Consultation 16/05/2024',
            name: 'consultation6',
            data: {
                prixEuro: 500,
                dureeMin: 60,
                date: '16/05/2024',
            },
        },
        {
            label: 'Consultation 17/05/2024',
            name: 'consultation7',
            data: {
                prixEuro: 500,
                dureeMin: 180,
                date: '17/05/2024',
            },
        },
        {
            label: 'Consultation 18/05/2024',
            name: 'consultation8',
            data: {
                prixEuro: 500,
                dureeMin: 60,
                date: '18/05/2024',
            },
        },
        {
            label: 'Consultation 19/05/2024',
            name: 'consultation9',
            data: {
                prixEuro: 500,
                dureeMin: 120,
                date: '19/05/2024',
            },
        },
        {
            label: 'Consultation 20/05/2024',
            name: 'consultation10',
            data: {
                prixEuro: 500,
                dureeMin: 60,
                date: '20/05/2024',
            },
        },
    ];

    const handleGenerate = () => {
        // setUserCase({});
    };
    const [checked, setChecked] = useState<OptionInterface[]>([]);

    return (
        <div className={styles.container}>
            <Card className={styles.billingForm}>
                <div className={styles.billingFormContainer}>
                    <Autocomplete options={optionsDossiers} label="Dossier" />
                    <Input type="date" label="Date de début" name="startDate" />
                    <Input type="date" label="Date de fin" name="endDate" />
                </div>
                <div className={styles.billingFormContainer}>
                    <Checkbox
                        label="Services :"
                        name="services"
                        options={optionsServices}
                        checked={checked}
                        setChecked={setChecked}
                    />
                </div>
                <Button onClick={handleGenerate}>Générer</Button>
            </Card>
            <Bill data={checked} />
        </div>
    );
}

export default Billing;
