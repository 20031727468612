import MeetingType from '../../../types/MeetingDateType';
import styles from './style.module.scss';
// import { GetTimeGapString } from "../../../helpers/date";
import { useQuery } from '@tanstack/react-query';
import { useOutletContext } from 'react-router-dom';
import Card from '../../../components/Card';
import LoaderDot from '../../../components/LoaderDot';
import Widgets from '../../../components/Widgets';
import Role from '../../../enums/RoleEnum';
import { fetchMyWidgets } from '../../../services/widgetService';
import UserType from '../../../types/UserType';
import WidgetType from '../../../types/WidgetType';

function Dashboard() {
    const user: UserType = useOutletContext();
    const date = new Date();
    const events: MeetingType[] = [
        {
            id: '1',
            title: 'Audiance M.Xxxxy',
            start: new Date(date.setHours(date.getHours() + 24)),
            end: new Date(date.setHours(date.getHours() + 1)),
        },
        {
            id: '2',
            title: 'Rdv Préfecture',
            start: new Date(date.setHours(date.getHours() + 2)),
            end: new Date(date.setHours(date.getHours() + 1)),
        },
        {
            id: '3',
            title: 'Autre',
            start: new Date(date.setHours(date.getHours())),
            end: new Date(date.setHours(date.getHours() + 1)),
        },
    ];

    events.sort((a, b) => {
        return a.start.getMilliseconds() - b.start.getMilliseconds();
    });
    const { isPending: isMyWidgetPending, data: myMidgets } = useQuery({
        queryKey: ['myWidgets'],
        queryFn: async () => {
            try {
                const { data } = await fetchMyWidgets();
                return data || [];
            } catch (error) {}
            return [];
        },
    });

    // const nextEventTimeLeft = GetTimeGapString(new Date(), events[0].start);
    return (
        <div className={styles.container}>
            <Card className={styles.header}>
                <h1>
                    {'Bonjour '}
                    {user?.role === Role.Lawyer
                        ? 'Maître ' + (user?.lastName || '')
                        : (user?.firstName || '') +
                          ' ' +
                          (user?.lastName || '')}
                    {/* vous avez rendez-vous dans {nextEventTimeLeft} */}
                </h1>
            </Card>
            <div className={styles.body}>
                {isMyWidgetPending ? (
                    <LoaderDot />
                ) : (
                    myMidgets?.map((widget: WidgetType) => (
                        <Widgets key={widget.id} name={widget.name} />
                    ))
                )}
            </div>
        </div>
    );
}

export default Dashboard;
