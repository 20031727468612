import { classNames } from '../../helpers/classNames';
import Button from '../Button';
import FaIcon from '../Icons/FaIcon';
import Input from '../Input';
import styles from './style.module.scss';

interface Props {
    placeholder?: string;
    searchBarClassName?: any;
    inputClassName?: any;
    btnClassName?: any;
    onChange?: (event: any) => any;
}

function SearchInput(props: Props) {
    const {
        searchBarClassName,
        inputClassName,
        btnClassName,
        onChange,
        placeholder,
    } = props;
    return (
        <div className={classNames([styles.searchBar, searchBarClassName])}>
            <Input
                placeholder={placeholder}
                inputClassNames={classNames([
                    styles.inputSearchBar,
                    inputClassName,
                ])}
                onChange={onChange}
            />
            <Button
                btnClassName={classNames([styles.btnSearchBar, btnClassName])}
            >
                <FaIcon>fa-solid fa-magnifying-glass</FaIcon>
            </Button>
        </div>
    );
}

export default SearchInput;
