import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useAlert } from '../../../AlertProvider';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import { alertType } from '../../../constants/alertTypeConstant';
import { successStatus } from '../../../constants/requestStatusContants';
import { validationFunctions } from '../../../constants/validationConstants';
import RegisterInterface from '../../../interfaces/RegisterInterface';
import { register } from '../../../services/accountService';
import styles from './style.module.scss';

function Register() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordChecked, setIsPasswordChecked] = useState<boolean>();
    const alert = useAlert();
    const setValueOnChange: any = {
        email: setEmail,
        password: setPassword,
        firstname: setFirstName,
        lastname: setLastName,
    };
    const { mutate } = useMutation({
        mutationFn: async (data: RegisterInterface) => {
            const { data: result, status } = await register(data);
            if (successStatus.includes(status)) {
                alert.show({
                    children:
                        'Votre compte a été créé avec succès. Vous pouvez maintenant vous connecter.',
                    type: alertType[status],
                });
            } else {
                alert.show({
                    children:
                        'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                    type: alertType[status],
                });
            }
            return result;
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isPasswordChecked || email.length === 0 || password.length === 0) {
            return;
        }
        mutate({
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            firstName: firstName,
            lastName: lastName,
        });
    };

    const handleConfirmPasswordChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;
        setIsPasswordChecked(password.length > 0 && password === value);
        setConfirmPassword(value);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setValueOnChange[name](value);
    };

    const lastNameError = validationFunctions.lastName(lastName);
    const firstNameError = validationFunctions.firstName(firstName);
    const passwordError = validationFunctions.password(password);
    const confirmPasswordError = validationFunctions.confirmPassword({
        password,
        confirmPassword,
    });

    return (
        <Card>
            <form
                action=""
                onSubmit={handleSubmit}
                className={styles.container}
            >
                <h2>Inscription</h2>
                <Input
                    label="Nom"
                    name="lastname"
                    onChange={handleChange}
                    error={lastNameError.errorMessage}
                />
                <Input
                    label="Prénom"
                    name="firstname"
                    onChange={handleChange}
                    error={firstNameError.errorMessage}
                />
                <Input label="Email" name="email" onChange={handleChange} />
                <Input
                    type="password"
                    label="Mot de passe"
                    name="password"
                    onChange={handleChange}
                    error={passwordError.errorMessage}
                />
                <Input
                    type="password"
                    label="Vérifier le mot de passe"
                    name="confirmPassword"
                    onChange={handleConfirmPasswordChange}
                    error={confirmPasswordError.errorMessage}
                />
                <div className={styles.action}>
                    <Button
                        type="submit"
                        isDisabled={
                            !lastNameError.isValid ||
                            !firstNameError.isValid ||
                            !passwordError.isValid ||
                            !confirmPasswordError.isValid
                        }
                    >
                        S'inscrire
                    </Button>
                </div>
            </form>
        </Card>
    );
}

export default Register;
