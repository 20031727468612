import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import Loader from '../components/Loader';
import {
    authenticationPage,
    dashboardPage,
} from '../constants/pagesRoutesContants';
import Role from '../enums/RoleEnum';

interface Props {
    children: any;
    roles: Role[];
}

const PrivateRoute = (props: Props) => {
    const { children, roles } = props;
    const { isAuth, user, role } = useAuth();

    if (!user) {
        return <Loader />;
    }

    if (!isAuth) {
        // Redirect to login if not authenticated
        return <Navigate to={authenticationPage.path} />;
    }

    if (roles && role && !roles.includes(role)) {
        // Redirect to home if user doesn't have the required role
        return <Navigate to={dashboardPage.path} />;
    }

    // Render the children if authenticated and authorized
    return children;
};

export default PrivateRoute;
