import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import ListCases from '..';
import Loader from '../../../../components/Loader';
import {
    fetchMyCase,
    fetchMyCases,
    postCase,
} from '../../../../services/caseService';

function MyCases() {
    // ajouter une page de détails des étapes du dossier
    const { pathname, hash: caseId } = useLocation();
    const { isPending: isPendingClientsCases, data } = useQuery({
        queryKey: ['clientCases', pathname],
        queryFn: fetchMyCases,
    });

    if (isPendingClientsCases) {
        return <Loader />;
    }
    return (
        <ListCases
            data={data?.data}
            caseId={caseId}
            detailRequest={fetchMyCase}
            formRequest={postCase}
        />
    );
}

export default MyCases;
