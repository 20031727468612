import { useQuery } from '@tanstack/react-query';
import Divider from '../../../components/Divider';
import Input from '../../../components/Input';
import { fetchMyLawfirm } from '../../../services/lawfirmService';
import LawfirmInformationSettings from './lawfirmInformationSettings';
import LawfirmLawyers from './lawfirmLawyers';
import LawfirmRoleAdmin from './lawfirmRoleAdmin';
import styles from './style.module.scss';

function LawfirmSettings() {
    const { data: lawfirm } = useQuery({
        queryKey: ['myLawfirm'],
        queryFn: async () => {
            const { data } = await fetchMyLawfirm();
            return data;
        },
    });
    return (
        <div className={styles.container}>
            <LawfirmInformationSettings lawfirm={lawfirm} />
            <Divider />
            <div>
                <h2>Style</h2>
                <Input type="file" label="Photo de couverture" />
            </div>
            <Divider />
            <LawfirmRoleAdmin lawyers={lawfirm?.lawyers} />
            <Divider />
            <LawfirmLawyers lawyers={lawfirm?.lawyers} />
            <Divider />
        </div>
    );
}

export default LawfirmSettings;
