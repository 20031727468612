import React from 'react';
import { classNames } from '../../helpers/classNames';
import styles from './style.module.scss';

interface Props {
    className?: any;
    children: React.ReactNode;
    cardSize?: {
        width: number;
        height: number;
    };
}

function Card(props: Props) {
    const { children, className } = props;
    return (
        <div className={classNames([styles.card, className])}>{children}</div>
    );
}

export default Card;
