import { useState } from 'react';
import { classNames } from '../../helpers/classNames';
import FaIcon from '../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    children?: any;
    active?: boolean;
}

function Carousel(props: Props) {
    const { active = false, children } = props;
    const img = [
        '/img/gavel-7499911_1920.jpg',
        '/img/lady-justice-2388500_1920.jpg',
        '/img/justice-2755765_1920.jpg',
    ];
    const imgLastLength = img.length;
    const imgLastPosition = imgLastLength - 1;
    const [currentImgPosition, setCurrentImgPosition] = useState(0);
    const activeCarousel = active && imgLastLength > 1;

    const onClickPrevious = (event: React.MouseEvent) => {
        if (currentImgPosition > 0) {
            return setCurrentImgPosition(currentImgPosition - 1);
        }
        return setCurrentImgPosition(imgLastPosition);
    };
    const onClickNext = (event: React.MouseEvent) => {
        if (currentImgPosition < imgLastPosition) {
            return setCurrentImgPosition(currentImgPosition + 1);
        }
        return setCurrentImgPosition(0);
    };

    return (
        <div
            className={classNames([styles.carousel])}
            style={{ backgroundImage: `url(${img[currentImgPosition]})` }}
        >
            {activeCarousel && (
                <div className={styles.btnPrevious}>
                    <button onClick={onClickPrevious}>
                        <FaIcon>fa-solid fa-chevron-left</FaIcon>
                    </button>
                </div>
            )}
            {children}
            {activeCarousel && (
                <div className={styles.btnNext}>
                    <button onClick={onClickNext}>
                        <FaIcon>fa-solid fa-chevron-right</FaIcon>
                    </button>
                </div>
            )}
        </div>
    );
}

export default Carousel;
