import { validationMessages } from '../constants/validationConstants';
import { ValidationResult } from '../interfaces/ValidationResultInterface';

export const validateEmail = (email: string): ValidationResult => {
    if (!email) {
        return {
            isValid: false,
            errorMessage: validationMessages.email.required,
        };
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        return {
            isValid: false,
            errorMessage: validationMessages.email.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateLoginPassword = (password: string): ValidationResult => {
    if (!password) {
        return {
            isValid: false,
            errorMessage: validationMessages.password.required,
        };
    }

    return { isValid: true, errorMessage: null };
};
export const validatePassword = (password: string): ValidationResult => {
    if (!password) {
        return {
            isValid: false,
            errorMessage: validationMessages.password.required,
        };
    }

    if (
        password.length < 8 &&
        validationMessages.password.formatValidation.tooShort
    ) {
        return {
            isValid: false,
            errorMessage: validationMessages.password.formatValidation.tooShort,
        };
    }

    const upperCaseRegex = /[A-Z]/;
    if (
        !upperCaseRegex.test(password) &&
        validationMessages.password.formatValidation.noUpperCase
    ) {
        return {
            isValid: false,
            errorMessage:
                validationMessages.password.formatValidation.noUpperCase,
        };
    }

    const lowerCaseRegex = /[a-z]/;
    if (
        !lowerCaseRegex.test(password) &&
        validationMessages.password.formatValidation.noLowerCase
    ) {
        return {
            isValid: false,
            errorMessage:
                validationMessages.password.formatValidation.noLowerCase,
        };
    }

    const digitRegex = /[0-9]/;
    if (
        !digitRegex.test(password) &&
        validationMessages.password.formatValidation.noDigit
    ) {
        return {
            isValid: false,
            errorMessage: validationMessages.password.formatValidation.noDigit,
        };
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (
        !specialCharRegex.test(password) &&
        validationMessages.password.formatValidation.noSpecialChar
    ) {
        return {
            isValid: false,
            errorMessage:
                validationMessages.password.formatValidation.noSpecialChar,
        };
    }

    return { isValid: true, errorMessage: null };
};

export const validateLastName = (lastName: string): ValidationResult => {
    if (!lastName) {
        return {
            isValid: false,
            errorMessage: validationMessages.lastName.required,
        };
    }
    if (lastName.length < 2) {
        return {
            isValid: false,
            errorMessage: validationMessages.lastName.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateFirstName = (firstName: string): ValidationResult => {
    if (!firstName) {
        return {
            isValid: false,
            errorMessage: validationMessages.firstName.required,
        };
    }
    if (firstName.length < 2) {
        return {
            isValid: false,
            errorMessage: validationMessages.firstName.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateDescription = (description: string): ValidationResult => {
    if (!description) {
        return {
            isValid: false,
            errorMessage: validationMessages.description.required,
        };
    }
    if (description.length > 500) {
        return {
            isValid: false,
            errorMessage: validationMessages.description.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateCivility = (civility: string): ValidationResult => {
    if (!civility) {
        return {
            isValid: false,
            errorMessage: validationMessages.civility.required,
        };
    }
    const validCivilities = ['Mr', 'Ms', 'Mrs'];
    if (!validCivilities.includes(civility)) {
        return {
            isValid: false,
            errorMessage: validationMessages.civility.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateZipCode = (zipCode: string): ValidationResult => {
    if (!zipCode) {
        return {
            isValid: false,
            errorMessage: validationMessages.zipCode.required,
        };
    }
    const zipCodeRegex = /^[0-9]{5}$/;
    if (!zipCodeRegex.test(zipCode)) {
        return {
            isValid: false,
            errorMessage: validationMessages.zipCode.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateCity = (city: string): ValidationResult => {
    if (!city) {
        return {
            isValid: false,
            errorMessage: validationMessages.city.required,
        };
    }
    if (city.length > 100) {
        return {
            isValid: false,
            errorMessage: validationMessages.city.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateCountry = (country: string): ValidationResult => {
    if (!country) {
        return {
            isValid: false,
            errorMessage: validationMessages.country.required,
        };
    }
    const validCountries = ['France', 'USA', 'Canada'];
    if (!validCountries.includes(country)) {
        return {
            isValid: false,
            errorMessage: validationMessages.country.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateConfirmEmail = (value: {
    email: string;
    confirmEmail: string;
}): ValidationResult => {
    const { email, confirmEmail } = value;
    if (!confirmEmail) {
        return {
            isValid: false,
            errorMessage: validationMessages.confirmEmail.required,
        };
    }
    if (email !== confirmEmail) {
        return {
            isValid: false,
            errorMessage: validationMessages.confirmEmail.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateConfirmPassword = (value: {
    password: string;
    confirmPassword: string;
}): ValidationResult => {
    const { password, confirmPassword } = value;
    if (!confirmPassword) {
        return {
            isValid: false,
            errorMessage: validationMessages.confirmPassword.required,
        };
    }
    if (password !== confirmPassword) {
        return {
            isValid: false,
            errorMessage: validationMessages.confirmPassword.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validatePhone = (phone: string): ValidationResult => {
    if (!phone) {
        return {
            isValid: false,
            errorMessage: validationMessages.phone.required,
        };
    }
    const phoneRegex = /^\+?[0-9]{10,15}$/;
    if (!phoneRegex.test(phone)) {
        return {
            isValid: false,
            errorMessage: validationMessages.phone.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateCurrentPassword = (
    currentPassword: string
): ValidationResult => {
    if (!currentPassword) {
        return {
            isValid: false,
            errorMessage: validationMessages.currentPassword.required,
        };
    }
    if (currentPassword.length < 8) {
        return {
            isValid: false,
            errorMessage: validationMessages.currentPassword.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};

export const validateNewPassword = (newPassword: string): ValidationResult => {
    if (!newPassword) {
        return {
            isValid: false,
            errorMessage: validationMessages.newPassword.required,
        };
    }
    if (newPassword.length < 8) {
        return {
            isValid: false,
            errorMessage: validationMessages.newPassword.formatValidation,
        };
    }
    return { isValid: true, errorMessage: null };
};
