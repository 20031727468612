import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAlert } from '../../../../../AlertProvider';
import Button from '../../../../../components/Button';
import AddressForm from '../../../../../components/FormEditor/AddressForm';
import DateForm from '../../../../../components/FormEditor/DateForm';
import IdentityForm from '../../../../../components/FormEditor/IdentityForm';
import PhoneForm from '../../../../../components/FormEditor/PhoneForm';
import FaIcon from '../../../../../components/Icons/FaIcon';
import { alertType } from '../../../../../constants/alertTypeConstant';
import { successStatus } from '../../../../../constants/requestStatusContants';
import { updateCase } from '../../../../../services/caseService';
import CaseType from '../../../../../types/CaseType';
import styles from './style.module.scss';

interface Props {
    caseData: CaseType;
}

function PersonnalData(props: Props) {
    const { caseData } = props;
    const alert = useAlert();

    const [editPersonnalData, setEditPersonnalData] = useState(false);
    const [personnalData, setPersonnalData] = useState<{ [key: string]: any }>(
        {}
    );
    useEffect(() => {
        setPersonnalData(caseData);
    }, [caseData]);

    const { mutate: savePersonnalData } = useMutation({
        mutationFn: async (userCase: CaseType) => {
            if (userCase) {
                const { data, status } = await updateCase(userCase);

                if (successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Vos modifications ont été enregistrées avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const handleEditPersonnalData = () => {
        setEditPersonnalData(true);
    };
    const handleChangePersonnalData = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target;
        const newData = {
            ...personnalData,
        };
        newData[name] = value;
        setPersonnalData(newData);
    };
    const handleSavePersonnalData = () => {
        const newCaseData: CaseType = personnalData as CaseType;
        savePersonnalData(newCaseData);
        setEditPersonnalData(false);
    };

    return (
        <div className={styles.infoContainer}>
            <h2 className={styles.titleAction}>
                <span>Informations Personnelles:</span>
                {!editPersonnalData ? (
                    <Button onClick={handleEditPersonnalData}>
                        <FaIcon>fa-edit</FaIcon>
                        Modifier
                    </Button>
                ) : (
                    <Button onClick={handleSavePersonnalData}>
                        <FaIcon>fa-save</FaIcon>
                        Enregistrer
                    </Button>
                )}
            </h2>
            {!editPersonnalData ? (
                <p className={styles.personnalData}>
                    <span className={styles.fullname}>
                        {personnalData.civility} {personnalData.firstName}{' '}
                        {personnalData.lastName}
                    </span>
                    <span className={styles.street}>
                        {personnalData.buildingNumber}{' '}
                        {personnalData.streetName}
                    </span>
                    <span className={styles.city}>
                        {personnalData.zipCode} {personnalData.city}{' '}
                        {personnalData.country}
                    </span>
                    <span className={styles.phone}>{personnalData.phone}</span>
                </p>
            ) : (
                <>
                    <IdentityForm
                        onChange={handleChangePersonnalData}
                        data={personnalData}
                    />
                    <DateForm
                        onChange={handleChangePersonnalData}
                        name={'birthDate'}
                        label="Date de naissance"
                        value={personnalData?.birthDate}
                    />
                    <AddressForm
                        onChange={handleChangePersonnalData}
                        data={personnalData}
                    />
                    <PhoneForm
                        onChange={handleChangePersonnalData}
                        value={personnalData.phone || ''}
                    />
                </>
            )}
        </div>
    );
}

export default PersonnalData;
