import userPhoto from '../../../../assets/user.jpg';
import LawyerType from '../../../../types/LawyerType';
import styles from './style.module.scss';

interface Props {
    lawyer: LawyerType;
}

function LawyerCover(props: Props) {
    const { lawyer } = props;

    const style: { [k: string]: any } = {};
    if (lawyer.cover) {
        style.backgroundImage = `url(${lawyer.cover})`;
    }
    return (
        <div className={styles.cover} style={style}>
            <div className={styles.photoContainer}>
                <img
                    src={lawyer.photo || userPhoto}
                    alt={lawyer.firstName + lawyer.lastName}
                    className={styles.photoCard}
                />
                <div className={styles.bodyId}>
                    {lawyer.firstName} {lawyer.lastName?.toLocaleUpperCase()}
                </div>
            </div>
        </div>
    );
}
export default LawyerCover;
