import Input from '../Input';

interface Props {
    name: string;
    label: string;
}

function FileInput(props: Props) {
    const { name, label } = props;
    return <Input name={name} label={label} type="file" />;
}

export default FileInput;
