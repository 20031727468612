import { classNames } from '../../helpers/classNames';
import NavDataInterface from '../../interfaces/NavDataInterface';
import ButtonVariant from '../ButtonVariant';
import FaIcon from '../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    toolbarData?: NavDataInterface[];
    isSticky?: boolean;
}

function Toolbar(props: Props) {
    const { toolbarData, isSticky = false } = props;
    return (
        <div
            className={classNames([
                styles.toolbar,
                isSticky ? styles.sticky : '',
            ])}
        >
            <div className={styles.start}>
                {toolbarData?.map(
                    (data) =>
                        data.position !== 'end' && (
                            <ButtonVariant
                                key={data.id}
                                link={data.link}
                                variant="secondaryLight"
                                onClick={(e) => {
                                    if (data.onClick) {
                                        data.onClick({ e, value: data });
                                    }
                                }}
                                isDisabled={data.isDisabled}
                            >
                                <div className={styles.btn}>
                                    {data.icon && (
                                        <span className={styles.icon}>
                                            <FaIcon type={data.iconInterface}>
                                                {data.icon}
                                            </FaIcon>
                                        </span>
                                    )}
                                    <span className={styles.label}>
                                        {data.label}
                                    </span>
                                </div>
                            </ButtonVariant>
                        )
                )}
            </div>
            <div className={styles.center}>
                {toolbarData?.map(
                    (data) =>
                        data.position === 'center' && (
                            <ButtonVariant
                                key={data.id}
                                link={data.link}
                                variant="secondaryLight"
                                onClick={(e) => {
                                    if (data.onClick) {
                                        data.onClick({ e, value: data });
                                    }
                                }}
                                isDisabled={data.isDisabled}
                            >
                                <div className={styles.btn}>
                                    {data.icon && (
                                        <span className={styles.icon}>
                                            <FaIcon type={data.iconInterface}>
                                                {data.icon}
                                            </FaIcon>
                                        </span>
                                    )}
                                    <span className={styles.label}>
                                        {data.label}
                                    </span>
                                </div>
                            </ButtonVariant>
                        )
                )}
            </div>
            <div className={styles.end}>
                {toolbarData?.map(
                    (data) =>
                        data.position === 'end' && (
                            <ButtonVariant
                                key={data.id}
                                link={data.link}
                                variant="secondaryLight"
                                onClick={(e) => {
                                    if (data.onClick) {
                                        data.onClick({ e, value: data });
                                    }
                                }}
                                isDisabled={data.isDisabled}
                            >
                                <div className={styles.btn}>
                                    {data.icon && (
                                        <span className={styles.icon}>
                                            <FaIcon type={data.iconInterface}>
                                                {data.icon}
                                            </FaIcon>
                                        </span>
                                    )}
                                    <span className={styles.label}>
                                        {data.label}
                                    </span>
                                </div>
                            </ButtonVariant>
                        )
                )}
            </div>
        </div>
    );
}

export default Toolbar;
