import { judicialCalendarEndpoint as endpoint } from '../constants/endpointsContants';
import JudicialEventInterface from '../interfaces/JudicialEventInterface';
import request from './genericRequestService';

async function fetchJudicialCalendar(id: string) {
    return await request.get({ endpoint, id });
}

async function postJudicialCalendar(data: JudicialEventInterface) {
    return await request.post({ endpoint, data });
}

async function updateJudicialCalendar(
    id: string,
    data: JudicialEventInterface
) {
    return await request.put({ endpoint, id, data });
}

async function deleteJudicialCalendar(id: string) {
    return await request.delete({ endpoint, id });
}

export {
    deleteJudicialCalendar,
    fetchJudicialCalendar,
    postJudicialCalendar,
    updateJudicialCalendar,
};
