import Input from '../../Input';
import styles from './style.module.scss';

interface Props {
    onChange?: (param: any) => any;
    name?: string;
    value?: string;
}

function PhoneForm(props: Props) {
    const { onChange, value = '', name = 'phone' } = props;
    return (
        <div className={styles.divInput}>
            <Input
                label="Téléphone"
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                max={10}
            />
        </div>
    );
}

export default PhoneForm;
