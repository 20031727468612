import { lawyerClientCaseEndpoint as endpoint } from '../constants/endpointsContants';
import LawyerToCaseInterface from '../interfaces/LawyerToCaseInterface';
import request from './genericRequestService';

async function addLawyerToCase(data: LawyerToCaseInterface) {
    return await request.post({ endpoint, data });
}

async function transferCaseToLawyer(data: LawyerToCaseInterface) {
    return await request.put({
        endpoint: endpoint + '/transfer',
        id: data.clientCaseId,
        data: {
            lawyerId: data.lawyerId,
        },
    });
}

async function rejectCase(id: string) {
    return await request.delete({ endpoint: endpoint + '/reject', id });
}

export { addLawyerToCase, rejectCase, transferCaseToLawyer };
