import { caseEndpoint as endpoint } from '../constants/endpointsContants';
import CreationCaseInterface from '../interfaces/CreationCaseInterface';
import StepInterface from '../interfaces/StepInterface';
import CaseType from '../types/CaseType';
import request from './genericRequestService';

async function fetchMyCase(caseId: string) {
    return await request.get({ endpoint, id: caseId });
}

async function fetchMyCases() {
    return await request.get({ endpoint });
}

async function fetchCaseTimeSpent(caseId: string) {
    return await request.get({ endpoint, id: caseId });
}

async function fetchClientCase(caseId: string) {
    return await request.get({
        endpoint: endpoint + '/client/Case',
        id: caseId,
    });
}

async function fetchClientCases() {
    return await request.get({ endpoint: endpoint + '/client' });
}

async function fetchClientCasesByUserId(userId: string) {
    return await request.get({ endpoint: endpoint + '/client', id: userId });
}

async function postClientCase(data: CreationCaseInterface) {
    return await request.post({ endpoint: endpoint + '/client', data });
}

async function postCase(data: CreationCaseInterface) {
    return await request.post({ endpoint, data });
}

async function updateCase(data: CaseType) {
    return await request.put({ endpoint, id: data.id, data });
}

async function refuseCase(userCase: CaseType) {
    return await request.put({ endpoint, id: userCase.id });
}

async function postCaseStep(data: StepInterface) {
    return await request.post({ endpoint, data });
}

async function updateCaseStep(data: StepInterface) {
    return await request.put({ endpoint, data });
}

async function fetchCaseBookmark() {
    return await request.get({ endpoint: endpoint + '/bookmark' });
}

async function postCaseBookmark(id: string) {
    return await request.post({ endpoint: endpoint + '/bookmark', id });
}

export {
    fetchCaseBookmark,
    fetchCaseTimeSpent,
    fetchClientCase,
    fetchClientCases,
    fetchClientCasesByUserId,
    fetchMyCase,
    fetchMyCases,
    postCase,
    postCaseBookmark,
    postCaseStep,
    postClientCase,
    refuseCase,
    updateCase,
    updateCaseStep,
};
