import { lawfirmEndpoint as endpoint } from '../constants/endpointsContants';
import LawfirmType from '../types/LawfirmType';
import request from './genericRequestService';

interface requeParams {
    speciality?: string;
    latitude?: number;
    longitude?: number;
    lawfirm?: string;
    lawyer?: string;
}

async function fetchLawfirms(params: requeParams) {
    return await request.get({ endpoint, params: params });
}

async function fetchLawfirmsByName(name: string) {
    return await request.get({ endpoint: endpoint + '/name', id: name });
}

async function fetchLawfirm(id: string) {
    return await request.get({ endpoint, id });
}
async function fetchMyLawfirm() {
    return await request.get({ endpoint: endpoint + '/me' });
}
async function updateMyLawfirm(data: LawfirmType) {
    return await request.put({ endpoint: endpoint + '/me', data });
}
export {
    fetchLawfirm,
    fetchLawfirms,
    fetchLawfirmsByName,
    fetchMyLawfirm,
    updateMyLawfirm,
};
