import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import ButtonLink from '../../../components/ButtonLink';
import Card from '../../../components/Card';
import Chip from '../../../components/Chip';
import FaIcon from '../../../components/Icons/FaIcon';
import Text from '../../../components/Text';
import { lawfirmsIdPage } from '../../../constants/pagesRoutesContants';
import { classNames } from '../../../helpers/classNames';
import { fetchLawyer } from '../../../services/lawyerService';
import LawyerType from '../../../types/LawyerType';
import LawyerCalendar from './calendar';
import LawyerCover from './cover';
import LawyerPrestation from './prestations';
import styles from './style.module.scss';

function Lawyer() {
    const { lawyerId } = useParams();

    const { data, isPending } = useQuery({
        queryKey: ['lawyer', lawyerId],
        queryFn: async () => {
            if (!lawyerId) {
                return null;
            }
            const { data } = await fetchLawyer(lawyerId);
            return data;
        },
    });

    const lawyer: LawyerType = data;

    if (isPending) return <div>Loading...</div>; // loading page

    return (
        <div className={classNames([styles.lawyerDetails])}>
            <LawyerCover lawyer={lawyer} />
            {!!lawyer.lawfirm && (
                <div className={styles.lawfirm}>
                    <ButtonLink
                        link={lawfirmsIdPage.path + lawyer.lawfirmId}
                        variant={'secondaryLight'}
                    >
                        Cabinet {lawyer.lawfirm}
                    </ButtonLink>
                </div>
            )}
            <div className={styles.body}>
                <div className={styles.bodyInfo}>
                    {lawyer.specialities.length > 0 && (
                        <div className={styles.bodySpeciality}>
                            {lawyer.specialities.map((spe) => (
                                <Chip key={spe.id}>{spe.name}</Chip>
                            ))}
                        </div>
                    )}
                    <div className={styles.bodyContact}>
                        <div>
                            <span className={styles.icon}>
                                <FaIcon>fa-map-location-dot</FaIcon>
                            </span>
                            <span>Adresse:</span>
                            <span>
                                <Text>
                                    {lawyer.buildingNumber} {lawyer.streetName}
                                </Text>
                            </span>
                            <span>
                                {lawyer.city} {lawyer.zipCode} {lawyer.country}
                            </span>
                        </div>
                        <div>
                            <span className={styles.icon}>
                                <FaIcon>fa-phone</FaIcon>
                            </span>
                            <span>Téléphone:</span>
                            <span>
                                <Text>{lawyer.phone}</Text>
                            </span>
                        </div>
                        <div>
                            <span className={styles.icon}>
                                <FaIcon>fa-envelope</FaIcon>
                            </span>
                            <span>Mail:</span>
                            <span>
                                <Text>{lawyer.email}</Text>
                            </span>
                        </div>
                    </div>
                    <Card className={styles.bodyDescription}>
                        <h3>Présentation : </h3>
                        <p>
                            <Text>
                                {lawyer.description ||
                                    `Avocat au Barreau de ${lawyer.lawyerBar}, Maître ${lawyer.lastName} exerce au ${lawyer.address} et propose des services juridiques adaptés aux besoins de ses clients, en les accompagnant dans différentes démarches juridiques.`}
                            </Text>
                        </p>
                    </Card>
                    <Card className={styles.bodyMeeting}>
                        <h3>Horaires : </h3>

                        <div className={styles.calendar}>
                            <LawyerCalendar lawyer={lawyer} />
                        </div>
                    </Card>
                    <Card className={styles.bodyPrice}>
                        <h3>Tarif : </h3>
                        <LawyerPrestation prestations={lawyer.prestations} />
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Lawyer;
