import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../AlertProvider';
import Button from '../../../../../components/Button';
import FaIcon from '../../../../../components/Icons/FaIcon';
import TextEditor from '../../../../../components/TextEditor';
import { alertType } from '../../../../../constants/alertTypeConstant';
import {
    requestStatus,
    successStatus,
} from '../../../../../constants/requestStatusContants';
import {
    deleteNote,
    postNote,
    updateNote,
} from '../../../../../services/noteService';
import CaseType from '../../../../../types/CaseType';
import NoteFormType from '../../../../../types/NoteFormType';
import CaseNoteTab from './caseNoteTab';
import styles from './style.module.scss';

interface Props {
    clientCase: CaseType;
}

function CaseNotes(props: Props) {
    const { clientCase } = props;
    const alert = useAlert();

    const newNoteTitle = 'Note - ' + new Date().toLocaleString();
    const newNote: NoteFormType = {
        text: '',
        title: newNoteTitle,
    };
    const [allNotes, setAllNotes] = useState<NoteFormType[]>([newNote]);
    const [selectedNote, setSelectedNote] = useState<NoteFormType>(newNote);
    const [noteText, setNoteText] = useState('');
    const [noteTitle, setNoteTitle] = useState(newNoteTitle);
    const [addNoteBtnActive, setAddNoteBtnActive] = useState(true);

    useEffect(() => {
        if (clientCase.notes?.length > 0) {
            setAllNotes(clientCase.notes);
            setSelectedNote(clientCase.notes?.[0]);
            setNoteText(clientCase.notes?.[0]?.text);
            setNoteTitle(clientCase.notes?.[0]?.title);
        }
        setAddNoteBtnActive(clientCase.notes?.length > 0);
    }, [clientCase]);

    const { mutate: saveNote } = useMutation({
        mutationFn: async (note: NoteFormType) => {
            note.clientCaseId = clientCase?.id;
            note.clientId = clientCase?.userId;
            if (!!selectedNote && !!selectedNote.id) {
                const result = await updateNote(selectedNote.id, note);

                if (successStatus.includes(result.status)) {
                    updatedNotes(result.data);
                    alert.show({
                        children: 'Votre demande a été envoyée avec succès',
                        type: alertType[result.status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[result.status],
                    });
                }
                return result;
            } else {
                const result = await postNote(note);
                setAddNoteBtnActive(true);

                if (successStatus.includes(result.status)) {
                    addNewNote(result.data);
                    alert.show({
                        children: 'Votre demande a été envoyée avec succès',
                        type: alertType[result.status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[result.status],
                    });
                }
                return result;
            }
        },
    });

    const { mutate: deleteThisNote } = useMutation({
        mutationFn: async () => {
            if (!!selectedNote && !!selectedNote.id) {
                const result = await deleteNote(selectedNote.id);
                if (result?.status === requestStatus.OK) {
                    const newList = removeFromList(allNotes, selectedNote.id);
                    setAllNotes(newList);
                    if (newList.length === 0) {
                        addNewNoteTab();
                    }
                }
                return result;
            }
        },
    });

    const removeFromList = (list: any[], id: string) => {
        const newList = list.filter((x) => x.id !== id);
        if (newList.length > 0) {
            setSelectedNote(newList[0]);
        }
        return newList;
    };

    const handleSelectNote = (note: NoteFormType) => {
        setSelectedNote(note);
    };

    const handleAddNewNote = () => {
        addNewNoteTab(allNotes);
    };

    const addNewNoteTab = (listNote: NoteFormType[] = []) => {
        setAddNoteBtnActive(false);
        setAllNotes([...listNote, newNote]);
        setSelectedNote(newNote);
        setNoteTitle(newNote.title);
        setNoteText(newNote.text);
    };

    const handleChangeNote = (value: any) => {
        setNoteText(value);
    };
    const handleSaveNote = () => {
        if (noteText.length > 0 && noteTitle.length > 0) {
            const udpatedNote: NoteFormType = {
                ...selectedNote,
            };
            udpatedNote.text = noteText;
            udpatedNote.title = noteTitle;

            saveNote(udpatedNote);
        }
    };

    const handleSetNoteTitle = (title: string) => {
        setNoteTitle(title);
    };

    const updatedNotes = (noteUpdated: NoteFormType) => {
        const newNoteList = [...allNotes];
        newNoteList.forEach((note: NoteFormType, index: number) => {
            if (note.id === noteUpdated.id) {
                newNoteList[index] = noteUpdated;
            }
        });
        setAllNotes(newNoteList);
    };

    const addNewNote = (noteAdded: NoteFormType) => {
        const newNoteList: NoteFormType[] = [...allNotes.filter((n) => !!n.id)];
        newNoteList.push(noteAdded);
        setSelectedNote(noteAdded);
        setAllNotes(newNoteList);
    };

    return (
        <div className={styles.infoContainer}>
            <h2 className={styles.titleAction}>
                <span>Notes:</span>
                <div className={styles.action}>
                    <Button onClick={handleSaveNote}>
                        <FaIcon>fa-save</FaIcon>
                        Enregistrer
                    </Button>
                    <Button onClick={deleteThisNote}>
                        <FaIcon>fa-trash</FaIcon>
                        Supprimer
                    </Button>
                </div>
            </h2>
            <div className={styles.noteContainer}>
                <div className={styles.noteTabs}>
                    {allNotes?.map((note) => (
                        <CaseNoteTab
                            key={'note_' + note.id}
                            note={note}
                            selectNote={handleSelectNote}
                            selectedNoteId={selectedNote?.id}
                            setNoteTitle={handleSetNoteTitle}
                            saveNote={handleSaveNote}
                        />
                    ))}
                    <Button
                        onClick={handleAddNewNote}
                        variant="secondary"
                        isDisabled={!addNoteBtnActive}
                    >
                        <FaIcon>fa-add</FaIcon>
                    </Button>
                </div>
                <TextEditor
                    onChange={handleChangeNote}
                    value={selectedNote?.text}
                />
            </div>
        </div>
    );
}

export default CaseNotes;
