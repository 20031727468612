import { useState } from 'react';
import { formats } from '../../../constants/formatsContants';
import { classNames } from '../../../helpers/classNames';
import StyleIconInterface from '../../../interfaces/StyleIconInterface';
import FaIcon from '../../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    children: string;
    id: string;
    onDblClick: (param: unknown) => unknown;
    fileFormat:
        | string
        | 'png'
        | 'jpeg'
        | 'flac'
        | 'mp3'
        | 'wav'
        | 'wma'
        | 'aa'
        | 'mpeg'
        | 'avi'
        | 'flv'
        | 'mp4'
        | 'zip'
        | 'rar'
        | '7z'
        | 'tar'
        | 'csv'
        | 'xlsx'
        | 'xls'
        | 'pdf'
        | 'ppt'
        | 'pptx'
        | 'doc'
        | 'docx'
        | 'folder'
        | 'folderOpen'
        | 'odt'
        | 'txt';
}

function FileItem(props: Props) {
    const { children, fileFormat, id, onDblClick } = props;
    const [icon, setIcon] = useState<string>(formatToIcon(fileFormat).Icon);
    const handleMouseEnter = () => {
        if (fileFormat === 'folder') {
            setIcon(formatToIcon('folderOpen').Icon);
        }
    };
    const handleMouseLeave = () => {
        if (fileFormat === 'folder') {
            setIcon(formatToIcon('folder').Icon);
        }
    };
    return (
        <div
            className={classNames([styles.file])}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onDoubleClick={() => {
                onDblClick(id);
            }}
        >
            <FaIcon classIcon={styles[formatToIcon(fileFormat).Style]}>
                {icon}
            </FaIcon>
            <span className={classNames([styles.filename])}>{children}</span>
        </div>
    );
}

function formatToIcon(format: string): StyleIconInterface {
    switch (true) {
        case formats.image.includes(format):
            return { Icon: 'fa-regular fa-file-image', Style: 'fa-file-image' };
        case formats.audio.includes(format):
            return { Icon: 'fa-regular fa-file-audio', Style: 'fa-file-audio' };
        case formats.video.includes(format):
            return { Icon: 'fa-regular fa-file-video', Style: 'fa-file-video' };
        case formats.archive.includes(format):
            return {
                Icon: 'fa-regular fa-file-archive',
                Style: 'fa-file-archive',
            };
        case formats.excel.includes(format):
            return { Icon: 'fa-regular fa-file-excel', Style: 'fa-file-excel' };
        case formats.pdf.includes(format):
            return { Icon: 'fa-regular fa-file-pdf', Style: 'fa-file-pdf' };
        case formats.powerpoint.includes(format):
            return {
                Icon: 'fa-regular fa-file-powerpoint',
                Style: 'fa-file-powerpoint',
            };
        case formats.word.includes(format):
            return { Icon: 'fa-regular fa-file-word', Style: 'fa-file-word' };
        case formats.folder.includes(format):
            return { Icon: 'fa-solid fa-folder', Style: 'fa-folder' };
        case formats.folderOpen.includes(format):
            return { Icon: 'fa-solid fa-folder-open', Style: 'fa-folder-open' };
        case formats.text.includes(format):
            return { Icon: 'fa-regular fa-file-text', Style: 'fa-file-text' };
        default:
            return { Icon: 'fa-regular fa-file', Style: 'fa-file' };
    }
}

export default FileItem;
