import { ChangeEvent, useEffect, useState } from 'react';
import { classNames } from '../../helpers/classNames';
import FaIcon from '../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    name?: string;
    label?: string;
    type?: string;
    icon?: string;
    placeholder?: string;
    onChange?: (param: any) => any;
    onBlur?: (param: any) => any;
    inputClassNames?: string;
    min?: any;
    max?: any;
    value?: string | number | readonly string[];
    isChecked?: boolean;
    disabled?: boolean;
    error?: string | null;
}

function Input(props: Props) {
    const {
        name,
        label,
        type,
        icon,
        placeholder,
        onChange,
        onBlur,
        isChecked,
        value = '',
        inputClassNames,
        disabled,
        max,
        error = '',
    } = props;

    const [inputValue, setInputValue] = useState(value);
    const [isTouched, setIsTouched] = useState(false);
    useEffect(() => {
        const val = value !== null && value !== undefined ? value : '';
        setInputValue(val);
    }, [value]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value: newValue } = event.target;

        if ((onChange && !max) || (onChange && max && newValue.length <= max)) {
            setInputValue(newValue);
            onChange(event);
        }
    };

    const handleFocus = () => {
        setIsTouched(true);
    };

    return (
        <div className={classNames([styles.divInput])}>
            {label && (
                <label className={classNames([styles.labelInput])}>
                    {icon && <FaIcon>{icon}</FaIcon>}
                    {label}
                </label>
            )}
            <input
                name={name}
                className={classNames([styles.input, inputClassNames])}
                onChange={handleOnChange}
                onBlur={onBlur}
                placeholder={placeholder}
                type={type}
                checked={isChecked}
                value={inputValue}
                disabled={disabled}
                onFocus={handleFocus}
            />
            {max && (
                <span className={styles.valueLength}>
                    {inputValue?.toString()?.length}/{max}
                </span>
            )}
            {isTouched && error && error?.length > 0 && (
                <span className={styles.errorMessage}>{error}</span>
            )}
        </div>
    );
}

export default Input;
