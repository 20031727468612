import styles from './style.module.scss';
function ClientsCasesGraphWidget() {
    return (
        <div className={styles.bodyLoader}>
            <span className={styles.loader}></span>
        </div>
    );
}

export default ClientsCasesGraphWidget;
