import { useState } from 'react';
import Button from '../../../components/Button';
import ServiceType from '../../../types/ServiceType';
import ServiceDeleteConfirmation from './serviceDeleteConfirmation';
import ServiceSaveForm from './serviceSaveForm';
import ServiceTable from './serviceTable';
import styles from './style.module.scss';

function Services() {
    const [modalSaveOpened, setModalSaveOpened] = useState(false);
    const [modalRemoveOpened, setModalRemoveOpened] = useState(false);

    const [service, setService] = useState<ServiceType | null>(null);
    const handleServiceClick = () => {
        setService(null);
        setModalSaveOpened(true);
    };
    return (
        <div className={styles.service}>
            <h1>Services:</h1>
            <div>
                <Button
                    onClick={() => {
                        handleServiceClick();
                    }}
                    name="add"
                >
                    Ajouter un service
                </Button>
            </div>
            <ServiceSaveForm
                service={service}
                modalOpened={modalSaveOpened}
                setModalOpened={setModalSaveOpened}
            />
            <ServiceDeleteConfirmation
                serviceId={service?.id}
                serviceName={service?.name}
                modalOpened={modalRemoveOpened}
                setModalOpened={setModalRemoveOpened}
            />
            <ServiceTable
                setService={setService}
                setModalSaveOpened={setModalSaveOpened}
                setModalRemoveOpened={setModalRemoveOpened}
            />
        </div>
    );
}

export default Services;
