import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useQuery } from '@tanstack/react-query';
import Card from '../../../components/Card';
import { fetchAvailabilities } from '../../../services/availabilityService';
import MeetingType from '../../../types/MeetingDateType';
import AvailabilitiesForm from './availabilitiesForm';
import styles from './style.module.scss';

function Availabilities() {
    // const { data: events, isPending } = useQuery({
    //   queryKey: ["meeting"],
    //   queryFn: async () => {
    //     const { data } = await fetchMyMeetings();
    //     return data;
    //   },
    // });

    const { data } = useQuery({
        queryKey: ['availabilities'],
        queryFn: async () => {
            const { data } = await fetchAvailabilities();
            return data;
        },
    });

    const headerToolbar = {
        start: 'prev,today', // will normally be on the left. if RTL, will be on the right
        center: 'title',
        end: 'timeGridWeek,timeGridDay,next', // will normally be on the right. if RTL, will be on the left
    };

    const onEventClick = (info: any) => {};

    const onDateSelected = (info: any) => {
        const selectedTime = new Date(info.start);
        if (selectedTime < new Date()) {
            alert(
                "Le créneau horaire selectionné n'est plus disponible. Veuillez en selectionner un autre."
            );
            return;
        }
    };
    const date = new Date();
    const events: MeetingType[] = [
        {
            id: '1',
            title: 'Audiance M.Xxxxy',
            start: new Date(date.setHours(date.getHours() + 24)),
            end: new Date(date.setHours(date.getHours() + 1)),
        },
        {
            id: '2',
            title: 'Rdv Préfecture',
            start: new Date(date.setHours(date.getHours() + 2)),
            end: new Date(date.setHours(date.getHours() + 1)),
        },
        {
            id: '3',
            title: 'Autre',
            start: new Date(date.setHours(date.getHours())),
            end: new Date(date.setHours(date.getHours() + 1)),
        },
    ];

    const weekendAvailabilities = data?.availabilitiesCalendar?.filter(
        (availability: any) => {
            return (
                availability.daysOfWeek.includes(0) ||
                availability.daysOfWeek.includes(6)
            );
        }
    );
    const weekendWorked = weekendAvailabilities?.length > 0;
    const today = new Date();
    const initialDate =
        !weekendWorked && today.getDay() === 6
            ? today.setDate(today.getDate() + 1)
            : today;

    return (
        <div className={styles.container}>
            <div className={styles.availabilities}>
                <AvailabilitiesForm data={data?.availabilities} />
            </div>
            <div className={styles.containerCalendar}>
                <div className={styles.eventContainer}>
                    <Card className={styles.card}>
                        <span>Evènements:</span>
                        <div className={styles.events}>
                            {events.map((event) => (
                                <span key={event.id} className={styles.event}>
                                    {event.start.toLocaleString()}:{' '}
                                    {event.title}
                                </span>
                            ))}
                        </div>
                    </Card>
                </div>
                <div className={styles.calendar}>
                    <FullCalendar
                        plugins={[
                            timeGridPlugin,
                            dayGridPlugin,
                            listPlugin,
                            interactionPlugin,
                        ]}
                        initialDate={initialDate}
                        initialView={'timeGridWeek'}
                        events={events}
                        eventClick={onEventClick}
                        businessHours={data?.availabilitiesCalendar}
                        selectable={true}
                        weekends={false}
                        select={onDateSelected}
                        nowIndicator={true}
                        locale={'fr'}
                        headerToolbar={headerToolbar}
                        selectConstraint={'businessHours'}
                        allDaySlot={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default Availabilities;
