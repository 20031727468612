import Card from '../../Card';
import Stopwatch from '../../Stopwatch';
import styles from './style.module.scss';
function StopwatchWidget() {
    return (
        <Card className={styles.widget}>
            <Stopwatch isMini={true} />
        </Card>
    );
}

export default StopwatchWidget;
