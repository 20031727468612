import { specialityEndpoint as endpoint } from '../constants/endpointsContants';
import request from './genericRequestService';

async function fetchSpecialities() {
    return await request.get({ endpoint });
}
async function fetchMySpecialities() {
    return await request.get({ endpoint: endpoint + '/me' });
}
async function addMySpeciality(id: string) {
    return await request.post({ endpoint: endpoint + '/me', id });
}
async function deleteMySpeciality(id: string) {
    return await request.delete({ endpoint: endpoint + '/me', id });
}

export {
    addMySpeciality,
    deleteMySpeciality,
    fetchMySpecialities,
    fetchSpecialities,
};
