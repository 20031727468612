import { lawyerEndpoint as endpoint } from '../constants/endpointsContants';
import EnrollLawyerInterface from '../interfaces/EnrollLawyerInterface';
import request from './genericRequestService';
interface requeParams {
    speciality?: string;
    latitude?: number;
    longitude?: number;
    lawfirm?: string;
    lawyer?: string;
}
async function fetchLawyers(
    params: requeParams,
    abortController?: AbortController
) {
    return await request.get({
        endpoint,
        params: { ...params, signal: abortController?.signal },
    });
}

async function fetchLawyer(id: string) {
    return await request.get({ endpoint, id });
}

async function fetchLawyersByName(name: string) {
    return await request.get({
        endpoint: endpoint + '/name',
        params: { name: name },
    });
}

async function fetchMyLawyersByName(name: string) {
    return await request.get({
        endpoint: endpoint + '/me/name',
        params: { name: name },
    });
}

async function lawyerEnrollment(data: EnrollLawyerInterface) {
    return await request.put({
        endpoint: endpoint + '/enroll',
        data,
    });
}
async function updateLawyer(data: { [key: string]: any }) {
    return await request.put({
        endpoint: endpoint,
        data: data,
    });
}

export {
    fetchLawyer,
    fetchLawyers,
    fetchLawyersByName,
    fetchMyLawyersByName,
    lawyerEnrollment as lawyerRegistration,
    updateLawyer,
};
