import { useMutation, useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useAlert } from '../../../../AlertProvider';
import { useAuth } from '../../../../AuthProvider';
import Button from '../../../../components/Button';
import ButtonLink from '../../../../components/ButtonLink';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import { alertType } from '../../../../constants/alertTypeConstant';
import { authenticationPage } from '../../../../constants/pagesRoutesContants';
import { successStatus } from '../../../../constants/requestStatusContants';
import { AddMinutes } from '../../../../helpers/date';
import OptionInterface from '../../../../interfaces/OptionInterface';
import { fetchMyCases } from '../../../../services/caseService';
import { postMeeting } from '../../../../services/meetingService';
import CaseType from '../../../../types/CaseType';
import MeetingType from '../../../../types/MeetingDateType';
import PrestationType from '../../../../types/PrestationType';
import styles from './style.module.scss';

interface Props {
    lawyerName: string;
    lawyerId: string;
    startDate: Date | null;
    prestations: PrestationType[];
    setIsModalOpened: (param: any) => any;
}

function LawyerMeetingModal(props: Props) {
    const { lawyerName, lawyerId, prestations, startDate, setIsModalOpened } =
        props;
    const alert = useAlert();

    const { isAuth } = useAuth();

    const [meetingSubject, setMeetingSubject] = useState('');
    const [prestation, setPrestation] = useState<PrestationType>();
    const [clientCase, setClientCase] = useState<CaseType>();
    const [endDate, setEndDate] = useState<Date>();

    const { mutate } = useMutation({
        mutationFn: async (meeting: MeetingType) => {
            if (meeting) {
                const { status, data } = await postMeeting(meeting);

                if (successStatus.includes(status)) {
                    alert.show({
                        children: 'Votre demande a été envoyée avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setMeetingSubject(value);
    };

    const { data: clientCases } = useQuery({
        queryKey: ['myClientCases'],
        queryFn: async () => {
            const { data } = await fetchMyCases();
            return data;
        },
    });

    const optionsLibrairy: { [key: string]: any } = {
        prestation: { action: setPrestation, data: prestations },
        clientCase: { action: setClientCase, data: clientCases },
    };

    const onSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value: id } = event.target;
        const data = optionsLibrairy[name].data.find((x: any) => x.id === id);
        optionsLibrairy[name].action(data);
        if (!!startDate && !!data && !!data.duration) {
            const dateEnd = AddMinutes(startDate, data.duration);
            setEndDate(dateEnd);
        }
    };

    const onClick = () => {
        if (!!startDate && !!endDate) {
            const event = {
                lawyerId: lawyerId,
                start: new Date(startDate.toUTCString()),
                end: new Date(endDate.toUTCString()),
                subject: meetingSubject,
                lawyerWorkServiceId: prestation?.id,
                clientCaseId: clientCase?.id,
            };
            mutate(event);
        }
        setIsModalOpened(false);
    };

    const prestationOptions: OptionInterface[] = prestations?.map(
        (prestation: PrestationType) => {
            return {
                label: `${prestation.name} (${prestation.price}€ - ${prestation.duration} min )`,
                id: prestation.id,
                name: prestation.name,
            };
        }
    );
    const clientCaseOptions: OptionInterface[] = clientCases?.map(
        (clientCase: CaseType) => {
            return {
                label: `${clientCase.caseName}`,
                id: clientCase.id,
                name: clientCase.caseRef,
            };
        }
    );

    return (
        <div className={styles.prestation}>
            <div className={styles.header}>
                <h4>Rendez-vous avec {lawyerName}</h4>
            </div>
            {isAuth ? (
                <div className={styles.body}>
                    <Select
                        placeholder="Prestations"
                        label="Prestations"
                        name="prestation"
                        options={prestationOptions}
                        onChange={onSelectChange}
                    />
                    <span>Date : {startDate?.toLocaleDateString()}</span>
                    <span>
                        Heure de début : {startDate?.toLocaleTimeString()}
                    </span>
                    <span>Heure de fin :{endDate?.toLocaleTimeString()} </span>
                    <span>Prix : {prestation?.price} €</span>
                    <Input
                        placeholder="Sujet"
                        name="subject"
                        onChange={onInputChange}
                    />
                    {isAuth && clientCases && (
                        <Select
                            placeholder="Dossiers"
                            label="Dossiers"
                            name="clientCase"
                            options={clientCaseOptions}
                            onChange={onSelectChange}
                        />
                    )}
                </div>
            ) : (
                <span className={styles.notConnected}>
                    Veuillez vous connecter pour prendre rendez-vous
                </span>
            )}
            <div className={styles.footer}>
                <Button
                    variant="secondaryLight"
                    onClick={() => setIsModalOpened(false)}
                >
                    Fermer
                </Button>
                {isAuth ? (
                    <Button onClick={onClick}>Valider</Button>
                ) : (
                    <ButtonLink link={authenticationPage.path}>
                        Se connecter
                    </ButtonLink>
                )}
            </div>
        </div>
    );
}

export default LawyerMeetingModal;
