import { noteEndpoint as endpoint } from '../constants/endpointsContants';
import NoteFormType from '../types/NoteFormType';
import request from './genericRequestService';

async function fetchNotes() {
    return await request.get({ endpoint });
}
async function fetchNoteById(id: string) {
    return await request.get({ endpoint, id });
}
async function updateNote(id: string, data: NoteFormType) {
    return await request.put({
        endpoint,
        id,
        data,
    });
}
async function postNote(data: NoteFormType) {
    return await request.post({ endpoint, data });
}
async function deleteNote(id: string) {
    return await request.delete({ endpoint, id });
}

export { deleteNote, fetchNoteById, fetchNotes, postNote, updateNote };
