import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SideNav from '../../../components/SideNav';
import Toolbar from '../../../components/Toolbar';
import NavDataInterface from '../../../interfaces/NavDataInterface';
import { fetchContact } from '../../../services/contactService';
import UserType from '../../../types/UserType';
import ContactDetails from './contactDetails';
import styles from './style.module.scss';

function Contact() {
    const { hash: contactId } = useLocation();
    const [typeAction, setTypeAction] = useState<'view_contact' | null>(null);

    const { data: contacts } = useQuery({
        queryKey: ['contacts'],
        queryFn: async () => {
            try {
                const { data } = await fetchContact();
                return data || [];
            } catch (error) {}
            return [];
        },
    });

    useEffect(() => {
        if (contactId) {
            setTypeAction('view_contact');
        }
    }, [contactId]);

    const navData: NavDataInterface[] = contacts?.map(
        (contact: UserType): NavDataInterface => {
            return {
                id: contact.id || '',
                label: `${contact.firstName} ${contact.lastName}`,
                link: '#' + contact.id,
                variant: 'tertiary',
                icon: 'fa-user',
                iconColor: '#ffffff',
            };
        }
    );

    const [toolbar, setToolbar] = useState<NavDataInterface[]>();

    return (
        <div className={styles.container}>
            <SideNav
                selected=""
                navData={navData}
                extendable={typeAction !== null}
                hasSearchBar={true}
                searchBarLabel="Contacts :"
            />
            <div className={styles.body}>
                <Toolbar toolbarData={toolbar} isSticky={typeAction !== null} />
                {typeAction && <ContactDetails setToolbar={setToolbar} />}
            </div>
        </div>
    );
}

export default Contact;
