import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useAlert } from '../../../../AlertProvider';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { alertType } from '../../../../constants/alertTypeConstant';
import { requestStatus } from '../../../../constants/requestStatusContants';
import ChangePasswordInterface from '../../../../interfaces/ChangePasswordInterface';
import { changePassword } from '../../../../services/accountService';
import styles from './style.module.scss';

function ChangePasswordSettings() {
    const alert = useAlert();

    const [passwordData, setPasswordData] = useState<ChangePasswordInterface>({
        currentPassword: '',
        newPassword: '',
    });

    const { mutate: save } = useMutation({
        mutationFn: async (passwordData: ChangePasswordInterface) => {
            const { data, status } = await changePassword(passwordData);
            const successStatus: number[] = [
                requestStatus.OK,
                requestStatus.Created,
                requestStatus.Accepted,
            ];
            if (successStatus.includes(status)) {
                alert.show({
                    children: 'Votre mot de passe a été modifié avec succès',
                    type: alertType[status],
                });
            } else {
                alert.show({
                    children:
                        'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                    type: alertType[status],
                });
            }
            return data;
        },
    });
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;

        const inputName = name as 'currentPassword' | 'newPassword';
        const newPasswordData = {
            ...passwordData,
        };
        newPasswordData[inputName] = value;
        setPasswordData(newPasswordData);
    };
    const handleClick = () => {
        save(passwordData);
    };

    return (
        <div>
            <div className={styles.passwordSettings}>
                <Input
                    type="password"
                    name="currentPassword"
                    label="Mot de passe actuel"
                    onChange={handleChange}
                    value={passwordData.currentPassword}
                />
                <Input
                    type="password"
                    name="newPassword"
                    label="Nouveau mot de passe"
                    onChange={handleChange}
                    value={passwordData.newPassword}
                />
            </div>
            <div className={styles.btn}>
                <Button onClick={handleClick}>Enregistrer</Button>
            </div>
        </div>
    );
}

export default ChangePasswordSettings;
