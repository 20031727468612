import { messageEndpoint as endpoint } from '../constants/endpointsContants';
import request from './genericRequestService';

async function fetchUserMessagesByConversationId(id: string, text: string) {
    return await request.get({
        endpoint: endpoint + '/conversation',
        id,
        params: text.length > 0 ? { text: text } : {},
    });
}

async function fetchConversationByRecipientId(id: string) {
    return await request.get({
        endpoint: endpoint + '/recipient',
        id,
    });
}

async function fetchUserConversations(text: string) {
    return await request.get({
        endpoint,
        params: text.length > 0 ? { text: text } : {},
    });
}

async function postUserMessagesByConversationId(id: string, text: string) {
    return await request.post({
        endpoint: endpoint + '/conversation',
        id,
        data: text,
    });
}
async function postUserMessagesByRecipientId(id: string, text: string) {
    return await request.post({
        endpoint: endpoint + '/recipient',
        id,
        data: text,
    });
}

export {
    fetchConversationByRecipientId,
    fetchUserConversations,
    fetchUserMessagesByConversationId,
    postUserMessagesByConversationId,
    postUserMessagesByRecipientId,
};
