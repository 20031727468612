import PricingCard from '../PricingCard';
import styles from './style.module.scss';

function PricingTable() {
    return (
        <div className={styles.pricingTable}>
            <PricingCard
                title="Offre Basique"
                price="14,99€ / mois"
                description="Plus 10% de commission"
                features={['Support 24/7', 'Accès aux fonctionnalités de base']}
            />
            <PricingCard
                title="Offre Avancée"
                price="49,99€ / mois"
                description="Plus 3% de commission"
                features={[
                    'Support 24/7',
                    'Accès à toutes les fonctionnalités',
                ]}
            />
            <PricingCard
                title="Offre Premium"
                price="124,99€ / mois"
                description="Sans commission"
                features={[
                    'Support 24/7',
                    'Accès à toutes les fonctionnalités',
                    'Consultations personnalisées',
                ]}
            />
            <PricingCard
                title="Essai Gratuit"
                price="Gratuit"
                description="3 réunions gratuites"
                features={[
                    '3 réunions gratuites',
                    'Accès limité aux fonctionnalités',
                ]}
            />
        </div>
    );
}

export default PricingTable;
