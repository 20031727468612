import { ChangeEvent, useEffect, useState } from 'react';
import Checkbox from '../../../../../components/Checkbox';
import Input from '../../../../../components/Input';
import OptionInterface from '../../../../../interfaces/OptionInterface';
import AvailabilityType from '../../../../../types/AvailabilityType';
import styles from './style.module.scss';

interface Props {
    dayAvailabilities?: AvailabilityType;
    day: {
        label: string;
        index: number;
    };
    isHolidays: boolean;
    onChange: (data: AvailabilityType) => void;
}

function DayAvailabilities(props: Props) {
    const { day, dayAvailabilities, isHolidays, onChange } = props;

    const initValue = {
        morningStartTime: '08:00',
        morningEndTime: '12:00',
        afternoonStartTime: '14:00',
        afternoonEndTime: '18:00',
        day: day.index,
    };
    const emptyValue = {
        day: day.index,
    };

    const checkboxOptions: OptionInterface[] = [
        {
            label: day.label,
            name: day.index.toString(),
        },
    ];

    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isChecked, setIsChecked] = useState<boolean>(true);

    const [availabilities, setAvailabilities] =
        useState<AvailabilityType>(emptyValue);

    useEffect(() => {
        if (dayAvailabilities) {
            setAvailabilities(dayAvailabilities);
        }

        const dayAvailabilitiesKeys = Object.keys({ ...dayAvailabilities });
        const isEmpty = dayAvailabilitiesKeys?.length <= 1;

        setIsDisabled(isEmpty);
        setIsChecked(!isEmpty);
    }, [dayAvailabilities]);

    const handleChangeCheckbox = (
        event: ChangeEvent<HTMLInputElement>,
        option: OptionInterface[]
    ) => {
        const isSelected: boolean = option.length === 1;
        setIsDisabled(!isSelected);
        setIsChecked(isSelected);
        const newAvailabilities = isSelected ? initValue : emptyValue;
        onChange(newAvailabilities);
        setAvailabilities(newAvailabilities);
    };

    const handleAvailabilitiesChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { value, name } = event.target;

        const newAvailabilities: { [key: string]: any } = { ...availabilities };
        if (
            newAvailabilities &&
            Object.keys(newAvailabilities).includes(name)
        ) {
            newAvailabilities[name] = value;
            setAvailabilities(newAvailabilities as AvailabilityType);
        }

        onChange({
            morningStartTime: newAvailabilities.morningStartTime,
            morningEndTime: newAvailabilities.morningEndTime,
            afternoonStartTime: newAvailabilities.afternoonStartTime,
            afternoonEndTime: newAvailabilities.afternoonEndTime,
            day: day.index,
        });
    };

    return (
        <div className={styles.days}>
            <div className={styles.labelDay}>
                <Checkbox
                    name={day.label}
                    onChange={handleChangeCheckbox}
                    options={checkboxOptions}
                    checked={isChecked ? checkboxOptions : []}
                    isDisabled={isHolidays}
                />
            </div>
            <div className={styles.timeDay}>
                <Input
                    data-id={day.index}
                    onChange={handleAvailabilitiesChange}
                    name="morningStartTime"
                    label="Début matin"
                    type="time"
                    value={availabilities?.morningStartTime}
                    disabled={isDisabled || isHolidays}
                />
                <Input
                    data-id={day.index}
                    onChange={handleAvailabilitiesChange}
                    name="morningEndTime"
                    label="Fin matin"
                    type="time"
                    min={availabilities?.morningStartTime}
                    value={availabilities?.morningEndTime}
                    disabled={isDisabled || isHolidays}
                />
                <Input
                    data-id={day.index}
                    onChange={handleAvailabilitiesChange}
                    name="afternoonStartTime"
                    label="Début après-midi"
                    type="time"
                    min={availabilities?.morningEndTime}
                    value={availabilities?.afternoonStartTime}
                    disabled={isDisabled || isHolidays}
                />
                <Input
                    data-id={day.index}
                    onChange={handleAvailabilitiesChange}
                    name="afternoonEndTime"
                    label="Fin après-midi"
                    type="time"
                    min={availabilities?.afternoonStartTime}
                    value={availabilities?.afternoonEndTime}
                    disabled={isDisabled || isHolidays}
                />
            </div>
        </div>
    );
}

export default DayAvailabilities;
