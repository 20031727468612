import Input from '../../Input';
import Select from '../../Select';
import styles from './style.module.scss';

interface Props {
    onChange?: (param: any) => any;
    data?: any;
}

function IdentityForm(props: Props) {
    const { onChange, data } = props;

    return (
        <div className={styles.identity}>
            <div className={styles.identityCivility}>
                <Select
                    name="civility"
                    label="Civilité"
                    placeholder="Civilité"
                    value={data?.civility}
                    options={[
                        { name: 'Monsieur', label: 'Monsieur' },
                        { name: 'Madame', label: 'Madame' },
                    ]}
                    onChange={onChange}
                />
            </div>
            <div className={styles.identityName}>
                <Input
                    name={'lastName'}
                    label="Nom"
                    type="text"
                    value={data?.lastName}
                    onChange={onChange}
                />
            </div>
            <div className={styles.identityFirstname}>
                <Input
                    name={'firstName'}
                    label="Prénom"
                    type="text"
                    value={data?.firstName}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

export default IdentityForm;
