import { widgetEndpoint as endpoint } from '../constants/endpointsContants';
import request from './genericRequestService';

async function fetchWidgets() {
    return await request.get({ endpoint: endpoint });
}
async function fetchMyWidgets() {
    return await request.get({ endpoint: endpoint + '/me' });
}
async function AddMyWidget(id: string) {
    return await request.post({ endpoint: endpoint + '/me', id: id });
}

export { AddMyWidget, fetchMyWidgets, fetchWidgets };
