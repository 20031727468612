function Cguv() {
    return (
        <div>
            <header>
                <h1>Conditions Générales d'Utilisation et de Vente (CGUV)</h1>
            </header>
            <main>
                <section>
                    <h2>1. Objet</h2>
                    <p>
                        Les présentes conditions générales d'utilisation et de
                        vente (ci-après « CGUV ») ont pour objet de définir les
                        modalités et conditions dans lesquelles [Nom du Site]
                        (ci-après « le Site ») fournit ses services aux
                        utilisateurs (ci-après « l'Utilisateur »).
                    </p>
                </section>

                <section>
                    <h2>2. Acceptation des CGUV</h2>
                    <p>
                        En utilisant le Site, l'Utilisateur accepte expressément
                        les présentes CGUV. Si l'Utilisateur n'accepte pas ces
                        CGUV, il doit cesser d'utiliser le Site.
                    </p>
                </section>

                <section>
                    <h2>3. Services Proposés</h2>
                    <p>Le Site permet à l'Utilisateur de :</p>
                    <ul>
                        <li>Trouver un avocat</li>
                        <li>Prendre rendez-vous avec un avocat</li>
                        <li>Créer et gérer des dossiers juridiques</li>
                        <li>Suivre l'avancement des dossiers</li>
                        <li>Discuter avec un avocat</li>
                    </ul>
                </section>

                <section>
                    <h2>4. Inscription</h2>
                    <p>
                        L'Utilisateur doit créer un compte pour accéder aux
                        services du Site. Lors de son inscription, l'Utilisateur
                        s'engage à fournir des informations exactes et à les
                        maintenir à jour.
                    </p>
                </section>

                <section>
                    <h2>5. Utilisation du Site</h2>
                    <p>
                        L'Utilisateur s'engage à utiliser le Site de manière
                        conforme à la loi et aux présentes CGUV. Il est
                        strictement interdit de :
                    </p>
                    <ul>
                        <li>Utiliser le Site à des fins illégales</li>
                        <li>
                            Diffuser des contenus illégaux, offensants, ou
                            diffamatoires
                        </li>
                        <li>
                            Tenter d'accéder de manière non autorisée au Site ou
                            à ses systèmes
                        </li>
                    </ul>
                </section>

                <section>
                    <h2>6. Tarification et Paiement</h2>
                    <p>
                        Les services proposés par le Site sont payants. Les
                        tarifs sont affichés sur le Site et peuvent être
                        modifiés à tout moment. Le paiement s'effectue en ligne
                        par carte bancaire ou tout autre moyen de paiement
                        accepté par le Site.
                    </p>
                </section>

                <section>
                    <h2>7. Politique de Remboursement</h2>
                    <p>
                        Les paiements effectués pour les services du Site ne
                        sont pas remboursables, sauf en cas de défaut de
                        prestation dû au Site.
                    </p>
                </section>

                <section>
                    <h2>8. Responsabilité</h2>
                    <p>
                        Le Site s'efforce de fournir des informations exactes et
                        à jour, mais ne garantit pas l'exactitude, la complétude
                        ou la pertinence des informations. Le Site ne pourra
                        être tenu responsable des dommages résultant de
                        l'utilisation des informations fournies.
                    </p>
                </section>

                <section>
                    <h2>9. Protection des Données Personnelles</h2>
                    <p>
                        Les données personnelles collectées par le Site sont
                        traitées conformément à la Politique de Confidentialité
                        du Site. L'Utilisateur dispose d'un droit d'accès, de
                        rectification et de suppression de ses données
                        personnelles.
                    </p>
                </section>

                <section>
                    <h2>10. Propriété Intellectuelle</h2>
                    <p>
                        Tous les contenus du Site (textes, images, logos, etc.)
                        sont protégés par le droit de la propriété
                        intellectuelle et sont la propriété exclusive du Site ou
                        de ses partenaires. Toute reproduction, représentation
                        ou distribution non autorisée est interdite.
                    </p>
                </section>

                <section>
                    <h2>11. Modification des CGUV</h2>
                    <p>
                        Le Site se réserve le droit de modifier les présentes
                        CGUV à tout moment. Les modifications seront effectives
                        dès leur publication sur le Site. L'utilisation continue
                        du Site après la publication des modifications vaut
                        acceptation des nouvelles CGUV.
                    </p>
                </section>

                <section>
                    <h2>12. Loi Applicable et Juridiction Compétente</h2>
                    <p>
                        Les présentes CGUV sont régies par la loi française.
                        Tout litige relatif à l'utilisation du Site sera soumis
                        à la compétence exclusive des tribunaux français.
                    </p>
                </section>

                <section>
                    <h2>13. Contact</h2>
                    <p>
                        Pour toute question concernant les présentes CGUV,
                        l'Utilisateur peut contacter le Site à l'adresse
                        suivante : [Adresse e-mail de contact]
                    </p>
                </section>
            </main>
        </div>
    );
}
export default Cguv;
