import { useState } from 'react';
import Modal from '../../../../../components/Modal';
import { Stepper } from '../../../../../components/Stepper';
import StepInterface from '../../../../../interfaces/StepInterface';
import CaseStepType from '../../../../../types/CaseStepType';
import DetailsStep from './stepDetails';
import styles from './style.module.scss';

interface Props {
    caseSteps: CaseStepType[];
}

function CaseSteps(props: Props) {
    // ajouter une page de détails des étapes du dossier
    const { caseSteps } = props;
    const [stepModalOpened, setStepModalOpened] = useState(false);
    const [stepData, setStepData] = useState<StepInterface | null>(null);

    const handleOnStepClick = (value: any) => {
        setStepData(value.data);
        setStepModalOpened(true);
    };

    const steps: StepInterface[] = caseSteps.map((step: CaseStepType) => {
        const stepData: StepInterface = { ...step, onClick: handleOnStepClick };
        return stepData;
    });
    // ajouter une page de détails des étapes du dossier

    return (
        <div className={styles.infoContainer}>
            <h2 className={styles.titleAction}>
                <span>Progression:</span>
                <Modal
                    modalOpened={stepModalOpened}
                    setModalOpened={setStepModalOpened}
                    title={stepData?.label}
                >
                    <DetailsStep stepData={stepData} />
                </Modal>
            </h2>
            <Stepper steps={steps} />
        </div>
    );
}

export default CaseSteps;
