import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../../../../AlertProvider';
import ModalAction from '../../../../components/ModalAction';
import { alertType } from '../../../../constants/alertTypeConstant';
import { successStatus } from '../../../../constants/requestStatusContants';
import { deleteService } from '../../../../services/serviceService';

interface Props {
    serviceId?: string;
    serviceName?: string;
    setModalOpened: (param: boolean) => void;
    modalOpened: boolean;
}

function ServiceDeleteConfirmation(props: Props) {
    const {
        serviceId = '',
        serviceName = '',
        setModalOpened,
        modalOpened,
    } = props;
    const alert = useAlert();

    const handleDeleteServiceClick = () => {
        mutate();
        setModalOpened(false);
    };
    const { mutate } = useMutation({
        mutationFn: async () => {
            const { data, status } = await deleteService(serviceId);
            if (successStatus.includes(status)) {
                alert.show({
                    children: 'Le service a été supprimé avec succès',
                    type: alertType[status],
                });
            } else {
                alert.show({
                    children:
                        'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                    type: alertType[status],
                });
            }
            return data;
        },
    });

    return (
        <ModalAction
            opened={modalOpened}
            setOpened={setModalOpened}
            onClick={() => {
                handleDeleteServiceClick();
            }}
        >
            <span>
                Voulez-vous vraiment supprimer le service {serviceName}?
            </span>
        </ModalAction>
    );
}

export default ServiceDeleteConfirmation;
