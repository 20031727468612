import {
    validateCity,
    validateCivility,
    validateConfirmEmail,
    validateConfirmPassword,
    validateCountry,
    validateCurrentPassword,
    validateDescription,
    validateEmail,
    validateFirstName,
    validateLastName,
    validateLoginPassword,
    validateNewPassword,
    validatePassword,
    validatePhone,
    validateZipCode,
} from '../helpers/validation';

export const validationMessages: { [key: string]: any } = {
    email: {
        formatValidation: 'Veuillez entrer une adresse e-mail valide.',
        required: "L'adresse e-mail est obligatoire.",
    },
    loginPassword: {
        formatValidation: '',
        required: 'Le mot de passe est obligatoire.',
    },
    confirmPassword: {
        formatValidation:
            'Le mot de passe et la confirmation ne correspondent pas.',
        required: 'La confirmation du mot de passe est obligatoire.',
    },
    password: {
        required: 'Le mot de passe est obligatoire.',
        formatValidation: {
            required: 'Le mot de passe est obligatoire.',
            tooShort: 'Le mot de passe doit comporter au moins 8 caractères.',
            noUpperCase:
                'Le mot de passe doit contenir au moins une lettre majuscule.',
            noLowerCase:
                'Le mot de passe doit contenir au moins une lettre minuscule.',
            noDigit: 'Le mot de passe doit contenir au moins un chiffre.',
            noSpecialChar:
                'Le mot de passe doit contenir au moins un caractère spécial (par ex. !@#$%^&*).',
        },
    },
    lastName: {
        formatValidation: 'Le nom doit comporter au moins 2 caractères.',
        required: 'Le nom est obligatoire.',
    },
    firstName: {
        formatValidation: 'Le prénom doit comporter au moins 2 caractères.',
        required: 'Le prénom est obligatoire.',
    },
    description: {
        formatValidation: 'La description ne peut pas dépasser 500 caractères.',
        required: 'La description est obligatoire.',
    },
    civility: {
        formatValidation: 'Veuillez sélectionner une civilité valide.',
        required: 'La civilité est obligatoire.',
    },
    zipCode: {
        formatValidation: 'Veuillez entrer un code postal valide.',
        required: 'Le code postal est obligatoire.',
    },
    city: {
        formatValidation:
            'Le nom de la ville ne peut pas dépasser 100 caractères.',
        required: 'Le nom de la ville est obligatoire.',
    },
    country: {
        formatValidation: 'Veuillez sélectionner un pays valide.',
        required: 'Le pays est obligatoire.',
    },
    confirmEmail: {
        formatValidation:
            "L'adresse e-mail et la confirmation ne correspondent pas.",
        required: "La confirmation de l'adresse e-mail est obligatoire.",
    },
    phone: {
        formatValidation: 'Veuillez entrer un numéro de téléphone valide.',
        required: 'Le numéro de téléphone est obligatoire.',
    },
    currentPassword: {
        formatValidation:
            'Le mot de passe actuel doit comporter au moins 8 caractères.',
        required: 'Le mot de passe actuel est obligatoire.',
    },
    newPassword: {
        formatValidation:
            'Le nouveau mot de passe doit comporter au moins 8 caractères.',
        required: 'Le nouveau mot de passe est obligatoire.',
    },
};

export const validationFunctions = {
    email: validateEmail,
    password: validatePassword,
    lastName: validateLastName,
    firstName: validateFirstName,
    description: validateDescription,
    civility: validateCivility,
    zipCode: validateZipCode,
    city: validateCity,
    country: validateCountry,
    confirmEmail: validateConfirmEmail,
    confirmPassword: validateConfirmPassword,
    phone: validatePhone,
    currentPassword: validateCurrentPassword,
    newPassword: validateNewPassword,
    loginPassword: validateLoginPassword,
};
