import React from 'react';
import { classNames } from '../../helpers/classNames';
import Button from '../Button';
import Card from '../Card';
import FaIcon from '../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    title?: string;
    children: React.ReactNode;
    modalOpened: boolean;
    setModalOpened: (param?: any) => any;
    onOpen?: (param?: any) => any;
    onClose?: (param?: any) => any;
}

function Modal(props: Props) {
    const { title, children, modalOpened, setModalOpened, onOpen, onClose } =
        props;

    if (onOpen) {
        onOpen();
    }

    const closeModal = (event: React.MouseEvent): void => {
        setModalOpened(false);
        if (onClose) {
            onClose();
        }
    };

    if (modalOpened)
        return (
            <div className={classNames([styles.modal])}>
                <Card className={styles.card}>
                    <Button
                        btnClassName={styles.btnClose}
                        onClick={closeModal}
                        variant="secondaryLight"
                    >
                        <FaIcon>fa-remove</FaIcon>
                    </Button>
                    {title && <span className={styles.title}>{title}</span>}
                    <div className={styles.body}>{children}</div>
                </Card>
            </div>
        );
    return <></>;
}

export default Modal;
