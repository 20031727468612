import userPhoto from '../../../../assets/user.jpg';
import LawyerType from '../../../../types/LawyerType';
import styles from './style.module.scss';

interface Props {
    lawyers: LawyerType[];
}

function LawfirmLawyers(props: Props) {
    const { lawyers } = props;

    // const { mutate } = useMutation({
    //   mutationFn: async (id: string) => {
    //     if (id.length > 0) {
    //       const result = await AddMyWidget(id);
    //       setWidgetList(result.data);
    //       return result;
    //     }
    //   },
    // });
    const onImageError = (event: any) => {
        event.target.style = 'display:none';
    };

    return (
        <div>
            <h2>Avocats du cabinet</h2>
            {lawyers?.length > 0 && (
                <div className={styles.lawyerList}>
                    {lawyers?.map((lawyer: LawyerType) => (
                        <div className={styles.lawyer} key={lawyer.id}>
                            <div className={styles.photoCard}>
                                <img
                                    src={lawyer.photo || userPhoto}
                                    alt={`${
                                        lawyer.firstName
                                    } ${lawyer.lastName?.toLocaleUpperCase()}`}
                                    onError={onImageError}
                                    className={styles.photo}
                                />
                            </div>
                            <span>
                                {`${lawyer.firstName} ${lawyer.lastName?.toLocaleUpperCase()}`}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default LawfirmLawyers;
