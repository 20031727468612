import Button from '../../Button';
import Card from '../../Card';
import FaIcon from '../../Icons/FaIcon';
import styles from './style.module.scss';
function TodayJudicialCalendarWidget() {
    return (
        <Card className={styles.widget}>
            <h2>Audience du jour</h2>
            <ul>
                <li>
                    <Button
                        variant="secondaryLight"
                        btnClassName={styles.widgetMsg}
                    >
                        <FaIcon>fa-clock</FaIcon>
                        <span>Rdv avec M.XXX yyyy dans 1h</span>
                    </Button>
                </li>
                <li>
                    <Button
                        variant="secondaryLight"
                        btnClassName={styles.widgetMsg}
                    >
                        <FaIcon>fa-clock</FaIcon>
                        <span>Rdv avec M.XXX xxxx dans 3h</span>
                    </Button>
                </li>
                <li>
                    <Button
                        variant="secondaryLight"
                        btnClassName={styles.widgetMsg}
                    >
                        <FaIcon>fa-clock</FaIcon>
                        <span>Audience dans 4h</span>
                    </Button>
                </li>
                <li>
                    <Button
                        variant="secondaryLight"
                        btnClassName={styles.widgetMsg}
                    >
                        <FaIcon>fa-clock</FaIcon>
                        <span>Rdv avec M.XYX yyxxy dans 6h</span>
                    </Button>
                </li>
            </ul>
        </Card>
    );
}

export default TodayJudicialCalendarWidget;
