import { useMutation } from '@tanstack/react-query';
import { ChangeEvent } from 'react';
import OptionInterface from '../../../interfaces/OptionInterface';
import { fetchAddress } from '../../../services/addressService';
import AddressType from '../../../types/AddressType';
import Autocomplete from '../../Autocomplete';

interface Props {
    userAddress: string;
    onOptionSelected: (value: AddressType) => void;
    onChange?: (value: any) => any;
}

function SearchByAddress(props: Props) {
    const { onOptionSelected, userAddress, onChange } = props;

    const {
        mutate,
        data: addresses,
        isPending,
    } = useMutation({
        mutationFn: async (address: string) => {
            try {
                if (address.trim().length > 0) {
                    const { data } = await fetchAddress(address);
                    return data;
                }
                return [];
            } catch (error) {}
            return [];
        },
    });

    const addressesOptions: OptionInterface[] = addresses?.map(
        (address: AddressType, index: number) => {
            return {
                id: 'address_' + index,
                name: address.address,
                label: address.address,
                data: address,
            } as OptionInterface;
        }
    );

    const handleChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        mutate(value);
        if (onChange) {
            onChange(event);
        }
    };

    function handleOptionClick(option?: OptionInterface) {
        onOptionSelected({
            latitude: option?.data.latitude,
            longitude: option?.data.longitude,
            address: option?.data.address,
        });
    }

    return (
        <Autocomplete
            options={addressesOptions}
            name="address"
            label="Adresse"
            onChange={handleChangeAddress}
            onOptionClick={handleOptionClick}
            value={userAddress}
            isDataLoading={isPending}
        />
    );
}

export default SearchByAddress;
