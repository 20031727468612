import Text from '../../../../components/Text';
import PrestationType from '../../../../types/PrestationType';

interface Props {
    prestations: PrestationType[];
}

function LawyerPrestation(props: Props) {
    const { prestations } = props;

    return (
        <Text>
            {prestations.map((prestation) => (
                <p key={prestation.id}>
                    <span>
                        {prestation.name} ({prestation.duration} min):
                    </span>
                    <span> {prestation.price} €</span>
                </p>
            ))}
        </Text>
    );
}

export default LawyerPrestation;
