import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useOutletContext } from 'react-router-dom';
import { useAlert } from '../../../AlertProvider';
import Button from '../../../components/Button';
import ButtonLink from '../../../components/ButtonLink';
import Card from '../../../components/Card';
import FaIcon from '../../../components/Icons/FaIcon';
import { alertType } from '../../../constants/alertTypeConstant';
import {
    clientCasesPage,
    myCasesPage,
} from '../../../constants/pagesRoutesContants';
import { successStatus } from '../../../constants/requestStatusContants';
import Role from '../../../enums/RoleEnum';
import { GetTimeGapString } from '../../../helpers/date';
import {
    confirmationMeeting,
    fetchMyMeetings,
} from '../../../services/meetingService';
import ConfirmationType from '../../../types/ConfirmationType';
import MeetingType from '../../../types/MeetingDateType';
import UserType from '../../../types/UserType';
import styles from './style.module.scss';

function Meetings() {
    const user: UserType = useOutletContext();
    const alert = useAlert();

    const { data: meetings } = useQuery({
        queryKey: ['meeting'],
        queryFn: async () => {
            const { data } = await fetchMyMeetings();
            return data;
        },
    });

    const { mutate } = useMutation({
        mutationFn: async (confirmation: ConfirmationType) => {
            try {
                const { data, status } =
                    await confirmationMeeting(confirmation);

                if (successStatus.includes(status)) {
                    alert.show({
                        children: 'Le rendez-vous a été confirmé avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            } catch (error) {}
            return [];
        },
    });

    const headerToolbar = {
        start: 'prev,today', // will normally be on the left. if RTL, will be on the right
        center: 'title',
        end: 'listWeek,next', // will normally be on the right. if RTL, will be on the left
    };

    const onEventClick = (info: any) => {};

    const onDateSelected = (info: any) => {
        const selectedTime = new Date(info.start);
        if (selectedTime < new Date()) {
            alert.show({
                children:
                    "Le créneau horaire selectionné n'est plus disponible. Veuillez en selectionner un autre.",
                type: 'error',
            });
            return;
        }
    };

    const events: MeetingType[] = meetings;

    // const meetingAction = {
    //   cancel: { name: "cancel", value: false },
    //   confirm: { name: "confirm", value: true },
    // };
    const handleValidateMeetingClick = (
        id: string | undefined,
        isConfirmed: boolean
    ) => {
        if (id) {
            mutate({ id, isConfirmed });
        }
    };
    const eventToValidate = events?.filter((event: any) => {
        return event.confirmationDate === null;
    });

    return (
        //mettre une liste des rdv avec Nom Prénom du client, numero de dossier et lien vers le dossier
        <div className={styles.containerCalendar}>
            <div className={styles.newMeetings}>
                {user?.role === Role.Lawyer && eventToValidate?.length > 0 && (
                    <div className={styles.eventContainer}>
                        <Card className={styles.card}>
                            <h3>Rendez-vous à confirmer:</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Rendez-vous</th>
                                        <th scope="col">Confirmation</th>
                                    </tr>
                                </thead>
                                <tbody className={styles.events}>
                                    {eventToValidate?.map((event) => (
                                        <tr
                                            className={styles.event}
                                            key={event.id}
                                        >
                                            <td>
                                                {new Date(
                                                    event.start
                                                ).toLocaleString()}
                                            </td>
                                            <td>{event.title}</td>
                                            <td>
                                                <Button
                                                    variant="primaryLight"
                                                    onClick={() =>
                                                        handleValidateMeetingClick(
                                                            event.id,
                                                            true
                                                        )
                                                    }
                                                >
                                                    <FaIcon>fa-check</FaIcon>
                                                </Button>
                                                <Button
                                                    variant="primaryLight"
                                                    onClick={() =>
                                                        handleValidateMeetingClick(
                                                            event.id,
                                                            true
                                                        )
                                                    }
                                                >
                                                    <FaIcon>fa-remove</FaIcon>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                )}
                <div className={styles.enrolled}>
                    <FullCalendar
                        plugins={[
                            timeGridPlugin,
                            dayGridPlugin,
                            listPlugin,
                            interactionPlugin,
                        ]}
                        initialDate={new Date()}
                        initialView={'listWeek'}
                        events={meetings}
                        eventClick={onEventClick}
                        selectable={true}
                        weekends={false}
                        select={onDateSelected}
                        nowIndicator={true}
                        locale={'fr'}
                        headerToolbar={headerToolbar}
                        selectConstraint={'businessHours'}
                        allDaySlot={false}
                    />
                </div>
            </div>
            <div className={styles.meetings}>
                <table>
                    <thead>
                        <tr>
                            <th scope="col">Type rendez-vous</th>
                            <th scope="col">Date</th>
                            <th scope="col">Durée</th>
                            <th scope="col">
                                {user?.role === Role.Lawyer
                                    ? 'Client'
                                    : 'Avocat'}
                            </th>
                            <th scope="col">Sujet</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody className={styles.events}>
                        {events?.map((event) => (
                            <tr className={styles.event} key={event.id}>
                                <td>{event.title}</td>
                                <td>
                                    {new Date(event.start).toLocaleString()}
                                </td>
                                <td>
                                    {GetTimeGapString(event.start, event.end)}
                                </td>
                                <td>
                                    {user?.role === Role.Lawyer
                                        ? event.client
                                        : event.lawyer}
                                </td>
                                <td>{event.subject}</td>
                                <td>
                                    {event.clientCaseId && (
                                        <ButtonLink
                                            link={
                                                (user?.role === Role.Lawyer
                                                    ? clientCasesPage.path
                                                    : myCasesPage.path) +
                                                '#' +
                                                event.clientCaseId
                                            }
                                        >
                                            Voir le dossier
                                        </ButtonLink>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Meetings;
