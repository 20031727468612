import { lawyerBarEndpoint as endpoint } from '../constants/endpointsContants';
import request from './genericRequestService';

async function fetchLawyerBars() {
    return await request.get({ endpoint });
}
async function updateMyLawyerBar(id: string) {
    return await request.put({ endpoint: endpoint + '/me', data: id });
}

export { fetchLawyerBars, updateMyLawyerBar };
