import styles from './style.module.scss';

interface Props {
    title: string;
    price: string;
    description: string;
    features: string[];
}

function PricingCard(props: Props) {
    const { title, price, description, features } = props;
    return (
        <div className={styles.pricingCard}>
            <h2>{title}</h2>
            <p className={styles.price}>{price}</p>
            <p className={styles.description}>{description}</p>
            <ul>
                {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
            <button className="btn">Choisir</button>
        </div>
    );
}

export default PricingCard;
