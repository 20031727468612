import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { storageItems } from '../../../constants/storageItemsContants';
import OptionInterface from '../../../interfaces/OptionInterface';
import { fetchLawyersByName } from '../../../services/lawyerService';
import LawyerType from '../../../types/LawyerType';
import Autocomplete from '../../Autocomplete';

interface Props {
    onOptionSelected: (value: LawyerType | null) => void;
    onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
}

function SearchByLawyer(props: Props) {
    const { onOptionSelected, onChange } = props;

    // si les valeurs suivantes sont vides aller chercher dans la base
    const [lawyerValue, setLawyerValue] = useState(
        localStorage.getItem(storageItems.lawyer) || ''
    );

    const { isPending, data: lawyers } = useQuery({
        queryKey: ['lawyers', { search: lawyerValue }],
        queryFn: async () => {
            // limiter à 10 avocats - faire un scroll infini (pagination)
            try {
                if (lawyerValue.length === 0) {
                    return [];
                }
                const { data } = await fetchLawyersByName(lawyerValue);
                return data || [];
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const lawyersOptions: OptionInterface[] = lawyers?.map(
        (lawyer: LawyerType) => {
            return {
                id: lawyer.id,
                name: `${lawyer.firstName} ${lawyer.lastName?.toLocaleUpperCase()}`,
                label: `${lawyer.firstName} ${lawyer.lastName?.toLocaleUpperCase()}`,
                data: lawyer,
            };
        }
    );

    const handleChangeLawyer = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setLawyerValue(value);
        if (onChange) {
            onChange(event);
        }
    };

    function handleOptionClick(option?: OptionInterface) {
        onOptionSelected(option?.data);
    }

    return (
        <Autocomplete
            options={lawyersOptions}
            label="Avocat"
            onChange={handleChangeLawyer}
            onOptionClick={handleOptionClick}
            value={lawyerValue}
            name="lawyer"
            isDataLoading={isPending}
        />
    );
}

export default SearchByLawyer;
