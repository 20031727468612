import { percentage, round, sum } from '../../../../helpers/number';
import OptionInterface from '../../../../interfaces/OptionInterface';
import styles from './style.module.scss';

interface Props {
    data: OptionInterface[];
}

function Bill(props: Props) {
    const { data } = props;
    const dateNow = new Date();
    const dueDate = new Date(new Date().setMonth(dateNow.getMonth() + 1));
    const tva = 20;
    const prixHT: number = sum(
        data.map((item) => {
            return round((item.data.prixEuro * item.data.dureeMin) / 60);
        })
    );
    const prixTVA = round(
        sum(
            data.map((item) => {
                return percentage(
                    (item.data.prixEuro * item.data.dureeMin) / 60,
                    20
                );
            })
        )
    );
    round((20 / 100) * prixHT);
    const prixTTC = prixHT + prixTVA;

    return (
        <div className={styles.billing}>
            {data !== null && (
                <div className={styles.billingContainer}>
                    <div className={styles.billingHeader}>
                        <div className={styles.billingLawfirmLogo}></div>
                        <div className={styles.billingTitle}>
                            <div className={styles.infoTitle}>
                                <label className={styles.titleLabel}>
                                    Facture n°
                                </label>
                                <span>DS-ZDSQ1233</span>
                            </div>
                            <div className={styles.infoTitle}>
                                <label className={styles.titleLabel}>
                                    Date de facturation:
                                </label>
                                <span>{dateNow.toLocaleDateString()}</span>
                            </div>
                            <div className={styles.infoTitle}>
                                <label className={styles.titleLabel}>
                                    Échéance :
                                </label>
                                <span>{dueDate.toLocaleDateString()}</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.billingInfo}>
                        <div className={styles.billingLawfirmInfo}>
                            <span>Adoté BLIVI</span>
                            <span>SCM Cabinet Taylor</span>
                            <span>5, rue Taylor</span>
                            <span>75010 Paris</span>
                            <span>0660583328</span>
                            <span>fablivi@yahoo.fr</span>
                        </div>
                        <div className={styles.billingClientInfo}>
                            <span>Elom BLIVI</span>
                            <span>5, rue Emmanuel KANT</span>
                            <span>94140 Alfortville</span>
                            <span>France</span>
                        </div>
                    </div>
                    <div className={styles.billingRef}>
                        <label>Reférence client : </label>
                        <span>EKB01051990</span>
                    </div>
                    <div className={styles.billingBody}>
                        <table className={styles.billingTable}>
                            <thead>
                                <tr>
                                    <th scope="col">Description</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Durée</th>
                                    <th scope="col">Prix/h</th>
                                    <th scope="col">TVA {tva}%</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item) => (
                                    <tr key={item.name}>
                                        <th scope="row">{item.label}</th>
                                        <td>{item.data.date}</td>
                                        <td>{round(item.data.dureeMin)} min</td>
                                        <td>{item.data.prixEuro}€</td>
                                        <td>
                                            {(tva / 100) * item.data.prixEuro}€
                                        </td>
                                        <td>
                                            {round(
                                                (tva / 100) *
                                                    item.data.prixEuro +
                                                    item.data.prixEuro *
                                                        (item.data.dureeMin /
                                                            60)
                                            )}
                                            €
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td rowSpan={3} colSpan={4}></td>
                                    <th scope="row">Total HT</th>
                                    <td>{prixHT || 0}€</td>
                                </tr>
                                <tr>
                                    <th scope="row">TVA {tva}%</th>
                                    <td>{prixTVA || 0}€</td>
                                </tr>
                                <tr>
                                    <th scope="row">Total TTC</th>
                                    <td>{prixTTC || 0}€</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className={styles.payment}>
                        <div className={styles.paymentInfo}>
                            <label className={styles.paymentLabel}>
                                Moyens de paiement:
                            </label>
                            <div className={styles.paymentData}>
                                <div>
                                    <label>Banque</label>
                                    <span>Crédit Lyonnais (LCL)</span>
                                </div>
                                <div>
                                    <label>SWIFT/BIC </label>
                                    <span>CRLYFRPP</span>
                                </div>
                                <div>
                                    <label>IBAN </label>
                                    <span>FR7630002032531234567890168</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.paymentInfo}>
                            <label className={styles.paymentLabel}>
                                Conditions de paiement :
                            </label>
                            <span className={styles.paymentData}>
                                Paiement comptant
                            </span>
                        </div>
                        <div className={styles.paymentInfo}>
                            <label className={styles.paymentLabel}>
                                Reférence client :
                            </label>
                            <span className={styles.paymentData}>
                                EKB01051990
                            </span>
                        </div>
                    </div>
                    <div className={styles.billingFooter}>
                        <span>SCM CABINET TAYLOR</span>
                        <span>
                            5, rue Taylor 75010 PARIS - Tél. : 01 44 52 14 35
                        </span>
                        <span>email:fablivi@yahoo.fr - PALAIS G 0017</span>
                        <span>
                            Membre d’une association agréée. Le règlement des
                            honoraires par chèque est accepté.
                        </span>
                        <span>N° SIRET 422 084 376 00033</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Bill;
