import { useState } from 'react';
import ButtonLink from '../../components/ButtonLink';
import Carousel from '../../components/Carousel';
import Divider from '../../components/Divider';
import SearchBySpeciality from '../../components/SearchBar/SearchBySpeciality';
import { lawyersPage } from '../../constants/pagesRoutesContants';
import { classNames } from '../../helpers/classNames';
import specialityType from '../../types/SpecialityType';
import styles from './style.module.scss';

function Home() {
    const [specialityValue, setSpecialityValue] =
        useState<specialityType | null>();

    return (
        <div className={classNames([styles.home])}>
            <Carousel>
                <div className={styles.divTitle}>
                    <h1 className={styles.title}>
                        Trouvez des Avocats dédiés à vos cas
                    </h1>
                    <Divider variant="primary" width="50%" />
                    <div className={styles.searchBar}>
                        <SearchBySpeciality
                            onOptionSelected={setSpecialityValue}
                            inputClassNames={styles.inputSearchBar}
                            placeholder="Sélectionnez la spécialité de votre avocat"
                            label=" "
                            speciality={specialityValue?.id}
                        />
                        <ButtonLink link={lawyersPage.path}>
                            Chercher
                        </ButtonLink>
                    </div>
                </div>
            </Carousel>
            <div className={styles.bodyInfo}>
                <div className={styles.homeInfoCard}>
                    <div className={styles.infoText}>
                        <h3>Un avocat à votre service, en un clic</h3>
                        <Divider width="100px" height="5px" />
                        <span>
                            Recherchez facilement l'avocat qui correspond à vos
                            besoins, près de chez vous. Prenez rendez-vous en
                            toute simplicité avec l'expert de votre choix.
                        </span>
                    </div>
                    <div className={styles.infoImage}>
                        <img src={'/img/office-620817_1280.jpg'} alt={''} />
                    </div>
                </div>
                <div className={styles.homeInfoCard}>
                    <div className={styles.infoText}>
                        <h3>
                            Créez et suivez votre dossier en toute simplicité
                        </h3>
                        <Divider width="100px" height="5px" />
                        <span>
                            Grâce à notre formulaire intuitif, constituez votre
                            dossier en quelques minutes. Vos informations sont
                            instantanément transmises à votre avocat, qui peut
                            vous demander des documents supplémentaires via
                            notre plateforme sécurisée.
                        </span>
                    </div>
                    <div className={styles.infoImage}>
                        <img
                            src={'/img/bureaucracy-2106924_1280.jpg'}
                            alt={''}
                        />
                    </div>
                </div>
                {/* <div className={styles.homeInfoCard}>
          <div className={styles.infoText}>
            <h3>Laisser vous trouver par votre avocat</h3>
            <Divider width="100px" height="5px" />
            <span>
              Créer votre dossier et nous le mettrons uniquement à disposition
              des avocats qui étudirons et viendrons proposer leur service. Ce
              dossier sera complêtement anonymisé et sécurisé. Vous pourrez
              ensuite selectionner l'avocat qu'il vous faut.
            </span>
          </div>
          <div className={styles.infoImage}>
            <img
              src={"/img/justice-2755765_1920.jpg"}
              alt={""}
            />
          </div>
        </div> */}
                {/* <div className={styles.homeInfoCard}>
          <div className={styles.infoText}>
            <h3>Conseil Juridique</h3>
            <Divider width="100px" height="5px" />
            <span>
              Vous avez une question juridique sans besoin d'avocat? Posé votre
              question simplement sur Liber’all. Un juriste vous répondra dans
              les plus bref délais. En cas de question plus complex il pourra
              également vous proposé un rendez-vous.
            </span>
          </div>
          <div className={styles.infoImage}>
            <img src={"/img/webinar-4216601_1280.jpg"} alt={""} />
          </div>
        </div> */}
                <div className={styles.homeInfoCard}>
                    <div className={styles.infoText}>
                        <h3>Une gestion transparente et réactive</h3>
                        <Divider width="100px" height="5px" />
                        <span>
                            Avec Liber'All, suivez l'évolution de votre dossier
                            en temps réel. Votre avocat vous transmet toutes les
                            informations nécessaires sans attente, pour une
                            collaboration fluide et efficace.
                        </span>
                    </div>
                    <div className={styles.infoImage}>
                        <img src={'/img/board-4876630_1280.jpg'} alt={''} />
                    </div>
                </div>
                {/* <div className={styles.homeInfoCard}>
          <div className={styles.infoText}>
            <h3>La sécurité est notre priorité</h3>
            <Divider width="100px" height="5px" />
            <span>
              Toutes les données saisie et enregistrer sur la plateforme sont
              cryptés afin de vous garantir un anonyma complet.
            </span>
          </div>
          <div className={styles.infoImage}>
            <img src={"/img/security-265130_1280.jpg"} alt={""} />
          </div>
        </div> */}
                {/* <div className={styles.homeInfoCard}>
          <div className={styles.infoText}>
            <h3>Liberez votre esprit</h3>
            <Divider width="100px" height="5px" />
            <span>
              Vous êtes avocat, Inscrivez vous pour accéder à des fonctionnalité
              qui vous faciliterons la vie. Gestions et suivi ( mettre à jour la
              progression du dossier afin de mettre le client au courant) de
              dossiers / Gestion des rendez-vous / Formulaire personnalisé/
              Template de documents / Traitement de texte / paiment en ligne
            </span>
          </div>
          <div className={styles.infoImage}>
            <img src={"/img/home-office-1867759_1280.jpg"} alt={""} />
          </div>
        </div> */}
                {/* <div className={styles.homeInfoCard}>
          <div className={styles.infoText}>
            <h3>Chez nous comme chez vous</h3>
            <Divider width="100px" height="5px" />
            <span>
              Liber’all vous permet également d’augmenter votre visibilié en
              vous créant une page dédié à votre cabinet. Vos aurez la
              possibilié de créé une Page du cabinet (bannière, logo, slogan,
              info du cabinet, avocats lié au cabinet), page de l’avocat (photo
              profil, info)
            </span>
          </div>
          <div className={styles.infoImage}>
            <img src={"/img/right-4703943_1280.jpg"} alt={""} />
          </div>
        </div> */}
                {/* <div className={styles.homeInfoCard}>
          <div className={styles.infoText}>
            <h3>Ensemble plus fort</h3>
            <Divider width="100px" height="5px" />
            <span>
              Associez vous avec d’autres avocats pour former votre cabinet en
              ligne. Demandez des conseils à d’autres avocats Simple et rapide:
              Organisez des rendez-vous de conseils rapides (15-20 min) en ligne
              (Visio ou Message).
            </span>
          </div>
          <div className={styles.infoImage}>
            <img src={"/img/people-1979261_1280.jpg"} alt={""} />
          </div>
        </div> */}
            </div>
        </div>
    );
}

export default Home;
