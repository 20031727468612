import StepInterface from '../../../../../../interfaces/StepInterface';
import styles from './style.module.scss';

interface Props {
    stepData: StepInterface | null;
}

function DetailsStep(props: Props) {
    const { stepData } = props;
    return (
        <div className={styles.modalStep}>
            <span>{stepData?.date.toLocaleString()}</span>
            <span>{stepData?.description}</span>
        </div>
    );
}

export default DetailsStep;
