interface Props {
    children?: any;
}

function Text(props: Props) {
    const { children } = props;
    return <>{children?.length > 0 ? children : 'NC'}</>;
}

export default Text;
