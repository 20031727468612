import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useAlert } from '../../../AlertProvider';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import { alertType } from '../../../constants/alertTypeConstant';
import { requestStatus } from '../../../constants/requestStatusContants';
import { validationFunctions } from '../../../constants/validationConstants';
import { forgotPassword } from '../../../services/accountService';
import styles from './style.module.scss';

function ForgotPassword() {
    const alert = useAlert();

    const [email, setEmail] = useState('');

    const { mutate } = useMutation({
        mutationFn: async (email: string) => {
            const { data, status } = await forgotPassword(email);
            const successStatus: number[] = [
                requestStatus.OK,
                requestStatus.Created,
                requestStatus.Accepted,
            ];
            if (successStatus.includes(status)) {
                alert.show({
                    children:
                        'Un lien de réinitialisation de mot de passe vous a été envoyé par email.',
                    type: alertType[status],
                });
            } else {
                alert.show({
                    children:
                        'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                    type: alertType[status],
                });
            }
            return data;
        },
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setEmail(value);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (email.length === 0) {
            return;
        }
        mutate(email);
    };
    const emailError = validationFunctions.email(email);
    return (
        <div className={styles.body}>
            <Card className={styles.container}>
                <h1>Mot de passe oublié</h1>
                <Input
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    error={emailError.errorMessage}
                />
                <div className={styles.action}>
                    <Button
                        onClick={handleSubmit}
                        isDisabled={!emailError.isValid}
                    >
                        Envoyer
                    </Button>
                </div>
            </Card>
        </div>
    );
}

export default ForgotPassword;
