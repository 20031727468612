import { useAuth } from '../../../AuthProvider';
import Divider from '../../../components/Divider';
import Input from '../../../components/Input';
import Role from '../../../enums/RoleEnum';
import ChangePasswordSettings from './changePassword';
import PersonalInformationSettings from './personalInformationSettings';
import ProfessionalInformationSettings from './professionalInformationSettings';
import styles from './style.module.scss';
import WidgetsSettings from './widgetsSettings';

function Settings() {
    const { user } = useAuth();

    return (
        <div className={styles.container}>
            <div className={styles.settings}>
                <h2>Informations personnelles</h2>
                <Divider />
                <PersonalInformationSettings user={user} />
            </div>
            {user?.role === Role.Lawyer && (
                <div className={styles.settings}>
                    <h2>Informations professionnelles</h2>
                    <Divider />
                    <ProfessionalInformationSettings lawyer={user?.lawyer} />
                </div>
            )}
            <div className={styles.settings}>
                <h2>Style</h2>
                <Divider />
                <Input type="file" label="Photo de profil" />
                <Input type="file" label="Photo de couverture" />
            </div>
            <div className={styles.settings}>
                <h2>Dashboard Widgets</h2>
                <Divider />
                <WidgetsSettings />
            </div>
            <div className={styles.settings}>
                <h2>Changer le mot de passe</h2>
                <Divider />
                <ChangePasswordSettings />
            </div>{' '}
        </div>
    );
}

export default Settings;
