const api: string = process.env.REACT_APP_API_BASE_URL || '';

function getEndpoint(params: string) {
    return createEndpoint([api, params]);
}
export function createEndpoint(params: string[]) {
    return params.filter(Boolean).join('/');
}
export const authEndpoint = getEndpoint('');
export const availabilityEndpoint = getEndpoint('availability');
export const holidaysEndpoint = getEndpoint('holidays');
export const locationEndpoint = getEndpoint('location');
export const caseEndpoint = getEndpoint('clientCase');
export const judicialCalendarEndpoint = getEndpoint('judicialCalendar');
export const fileExplorerEndpoint = getEndpoint('fileExplorer');
export const formEditorEndpoint = getEndpoint('formEditor');
export const lawfirmEndpoint = getEndpoint('lawfirm');
export const lawyerEndpoint = getEndpoint('lawyer');
export const lawyerClientCaseEndpoint = getEndpoint('lawyerClientCase');
export const userEventEndpoint = getEndpoint('userEvent');
export const messageEndpoint = getEndpoint('message');
export const serviceEndpoint = getEndpoint('lawyerWorkService');
export const settingsEndpoint = getEndpoint('settings');
export const specialityEndpoint = getEndpoint('speciality');
export const userEndpoint = getEndpoint('user');
export const widgetEndpoint = getEndpoint('widget');
export const userAccountEndpoint = getEndpoint('user/account');
export const contactEndpoint = getEndpoint('contact');
export const lawyerBarEndpoint = getEndpoint('lawyerBar');
export const noteEndpoint = getEndpoint('note');
