import { classNames } from '../../../helpers/classNames';

interface Props {
    children: string;
    classIcon?: string;
    styleIcon?: React.CSSProperties;
    type?: 'regular';
}

function FaIcon(props: Props) {
    const { children, classIcon, styleIcon, type = 'solid' } = props;
    return (
        <i
            className={classNames(['fa-' + type, children, classIcon])}
            style={styleIcon}
        ></i>
    );
}

export default FaIcon;
