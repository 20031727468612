function round(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

function sum(num: number[]): number {
    return num.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
    );
}

function percentage(num: number, percentageNum: number): number {
    return (num * percentageNum) / 100;
}
export { percentage, round, sum };
