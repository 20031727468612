import { createContext, ReactNode, useContext, useState } from 'react';
import Alert from '../components/Alert';
import AlertInterface from '../interfaces/AlertInterface';
import AlertProviderInterface from '../interfaces/AlertProviderInterface';

interface Props {
    children: ReactNode;
}
// Create a context for the alert
const AlertContext = createContext<AlertProviderInterface>(
    {} as AlertProviderInterface
);
// Create the provider component
export const AlertProvider = (props: Props) => {
    const { children } = props;
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [alertData, setAlertData] = useState<AlertInterface>({});

    const showAlert = (alert: AlertInterface) => {
        setAlertData(alert);
        setIsAlertVisible(true);
    };

    const hideAlert = () => {
        if (alertData.onClose) {
            alertData.onClose();
        }
        setIsAlertVisible(false);
        setAlertData({});
    };

    return (
        <AlertContext.Provider
            value={{
                isVisible: isAlertVisible,
                data: alertData,
                show: showAlert,
                hide: hideAlert,
            }}
        >
            <Alert />
            {children}
        </AlertContext.Provider>
    );
};

// Custom hook to use the alert context
export const useAlert = () => {
    return useContext(AlertContext);
};
