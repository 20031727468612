import Checkbox from '../components/Checkbox';
import FileInput from '../components/FileInput';
import AddressForm from '../components/FormEditor/AddressForm';
import DateForm from '../components/FormEditor/DateForm';
import EmailForm from '../components/FormEditor/EmailForm';
import IdentityForm from '../components/FormEditor/IdentityForm';
import PhoneForm from '../components/FormEditor/PhoneForm';
import Input from '../components/Input';
import Radio from '../components/Radio';
import Select from '../components/Select';
import Textaera from '../components/Textaera';
// import SwitchButton from "../Components/switch-button/switch-button";

export const formElements: { [key: string]: any } = {
    identity: { name: 'Identité', element: IdentityForm },
    address: { name: 'Adresse', element: AddressForm },
    phone: { name: 'Téléphone', element: PhoneForm },
    email: { name: 'Email', element: EmailForm },
    date: { name: 'Date', element: DateForm },
    file: { name: 'Fichier', element: FileInput },
    input: { name: 'Texte', element: Input },
    textaera: { name: 'Texte long', element: Textaera },
    select: { name: 'Selection', element: Select },
    radio: { name: 'Choix unique', element: Radio },
    checkbox: { name: 'Choix multiple', element: Checkbox },
    // switch: { name: "Switch", element: SwitchButton },
};
