import PricingSimulator from '../../components/PricingSimulator';
import PricingTable from '../../components/PricingTable';
import styles from './style.module.scss';

function Pricing() {
    return (
        <div className={styles.plans}>
            <PricingTable />
            <PricingSimulator />
        </div>
    );
}

export default Pricing;
