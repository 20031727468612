import styles from './style.module.scss';
function NotFound() {
    return (
        <div className={styles.loaderBody}>
            <span className={styles.loader}>Not Found</span>
        </div>
    );
}

export default NotFound;
