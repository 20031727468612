import { userEventEndpoint as endpoint } from '../constants/endpointsContants';
import ConfirmationType from '../types/ConfirmationType';
import MeetingType from '../types/MeetingDateType';
import request from './genericRequestService';

async function fetchMyMeetings() {
    return await request.get({ endpoint });
}

async function fetchUserMeetings(id: string) {
    return await request.get({ endpoint, id });
}

async function postMeeting(data: MeetingType) {
    return await request.post({ endpoint, data });
}

async function confirmationMeeting(data: ConfirmationType) {
    return await request.put({
        endpoint: endpoint + '/confirmation',
        id: data.id,
        data: data.isConfirmed,
    });
}
export { confirmationMeeting, fetchMyMeetings, fetchUserMeetings, postMeeting };
