import { classNames } from '../../../helpers/classNames';
import FaIcon from '../../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    children: string;
    sort?: 'descending' | 'ascending';
}

function FilterElement(props: Props) {
    const { children, sort } = props;
    return (
        <span className={classNames([styles.feElement])}>
            <span className={classNames([styles.feElementName])}>
                {children}
            </span>
            <FaIcon>{orientationToIcon(sort)}</FaIcon>
        </span>
    );
}

function orientationToIcon(sort?: string): string {
    switch (sort) {
        case 'descending':
            return 'fa-sort-desc';
        case 'ascending':
            return 'fa-sort-asc';
        default:
            return 'fa-sort';
    }
}

export default FilterElement;
