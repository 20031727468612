import userPhoto from '../../../assets/user.jpg';
import ButtonLink from '../../../components/ButtonLink';
import FaIcon from '../../../components/Icons/FaIcon';
import { lawyersIdPage } from '../../../constants/pagesRoutesContants';
import { classNames } from '../../../helpers/classNames';
import LawyerType from '../../../types/LawyerType';
import styles from './style.module.scss';

interface Props {
    lawyer: LawyerType;
}

function LawyerPopup(props: Props) {
    const { lawyer } = props;

    return (
        <div key={lawyer.id} className={styles.cardContainer} id={lawyer.id}>
            <div className={styles.coverCard}>
                <div className={styles.photoContainer}>
                    <img
                        src={lawyer.photo || userPhoto}
                        alt={lawyer.firstName + lawyer.lastName}
                        className={styles.photoCard}
                    />
                </div>
            </div>
            <div className={styles.cardBody}>
                <div
                    className={classNames([
                        styles.cardBodyId,
                        window.location.hash.includes(lawyer.id)
                            ? styles.underlined
                            : '',
                    ])}
                >
                    {lawyer.firstName} {lawyer.lastName?.toLocaleUpperCase()}
                </div>
                <div className={styles.cardSpe}>
                    {lawyer.specialities.map((spe) => (
                        <div key={spe.id} className={styles.chip}>
                            {spe.name}
                        </div>
                    ))}
                </div>
                <div className={styles.cardBodyInfo}>
                    <span>
                        <FaIcon>fa-house</FaIcon>
                        {lawyer.lawfirm}
                    </span>
                    <span>
                        <FaIcon>fa-map-marker</FaIcon>
                        {lawyer.address}
                    </span>
                </div>
            </div>
            <div className={styles.cardFooter}>
                <ButtonLink
                    link={lawyersIdPage.path + lawyer.id}
                    variant="secondary"
                >
                    Voir
                </ButtonLink>
            </div>
        </div>
    );
}

export default LawyerPopup;
