import ButtonLink from '../../../components/ButtonLink';
import { lawfirmsIdPage } from '../../../constants/pagesRoutesContants';
import LawfirmType from '../../../types/LawfirmType';
import styles from './style.module.scss';

interface Props {
    lawfirm: LawfirmType;
}

function LawfirmPopup(props: Props) {
    const { lawfirm } = props;
    return (
        <div key={lawfirm.id} className={styles.cardContainer}>
            <div className={styles.coverCard}>
                <span className={styles.name}>{lawfirm.name}</span>
            </div>
            <div className={styles.cardBody}>
                <div className={styles.cardBodyInfo}>
                    <span>{lawfirm.address}</span>
                </div>
            </div>
            <div className={styles.cardFooter}>
                <ButtonLink
                    link={lawfirmsIdPage.path + lawfirm.id}
                    variant="secondary"
                >
                    Voir
                </ButtonLink>
            </div>
        </div>
    );
}

export default LawfirmPopup;
