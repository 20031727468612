import { useQuery } from '@tanstack/react-query';
import { Icon } from 'leaflet';
import { ChangeEvent, useEffect, useState } from 'react';
import location from '../../assets/location.svg';
import Map from '../../components/Map';
import SearchBar from '../../components/SearchBar';
import { storageItems } from '../../constants/storageItemsContants';
import { classNames } from '../../helpers/classNames';
import MapDataInterface from '../../interfaces/MapDataInterface';
import { fetchLawyers } from '../../services/lawyerService';
import LawyerType from '../../types/LawyerType';
import LawyerCard from './lawyerCard';
import LawyerPopup from './lawyerPopup';
import styles from './style.module.scss';

function Lawyers() {
    const [params, setParams] = useState<{ [key: string]: any }>({});
    const [lawyersList, setLawyersList] = useState<LawyerType[]>([]);

    useEffect(() => {
        const speciality = sessionStorage.getItem(storageItems.speciality);
        setParams({
            speciality: speciality,
        });
        sessionStorage.removeItem(storageItems.speciality);
    }, [setParams]);

    const { isPending: isPendingLayers } = useQuery({
        queryKey: ['lawyers', params],
        queryFn: async () => {
            try {
                const { data } = await fetchLawyers(params);
                setLawyersList(data);
            } catch (error) {}
            return [];
        },
    });

    const lawyerIcon = new Icon({
        iconUrl: location,
        iconSize: [32, 32],
    });

    const mapData: MapDataInterface[] = lawyersList.map(
        (lawyer: LawyerType) => {
            return {
                id: lawyer.id,
                label: `${lawyer.firstName} ${lawyer.lastName?.toLocaleUpperCase()}`,
                latitude: lawyer.latitude,
                longitude: lawyer.longitude,
                link: `#${lawyer.id}`,
                icon: lawyerIcon,
                popup: <LawyerPopup lawyer={lawyer} />,
            };
        }
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const newParams = { ...params };
        newParams[name] = null;
        setParams(newParams);
    };

    const userLocation = {
        address: params.address,
        latitude: params.latitude,
        longitude: params.longitude,
    };

    if (isPendingLayers && lawyersList.length < 1) {
        return <div>Loading...</div>;
    }

    return (
        <div className={classNames([styles.lawyers])}>
            <div className={styles.map}>
                <Map data={mapData} userLocation={userLocation} />
            </div>
            <div className={styles.searchBarContainer}>
                <SearchBar
                    setParams={setParams}
                    params={params}
                    onChange={handleChange}
                />
            </div>
            {lawyersList.map((lawyer: LawyerType) => (
                <LawyerCard
                    lawyer={lawyer}
                    key={lawyer.id}
                    specialityId={params.speciality}
                />
            ))}
        </div>
    );
}

export default Lawyers;
