import { useQuery } from '@tanstack/react-query';
import { clientCasesIdPage } from '../../../constants/pagesRoutesContants';
import { fetchCaseBookmark } from '../../../services/caseService';
import CaseType from '../../../types/CaseType';
import Card from '../../Card';
import FaIcon from '../../Icons/FaIcon';
import LoaderDot from '../../LoaderDot';
import styles from './style.module.scss';
function ClientsCasesBookmarkWidget() {
    const { data, isPending } = useQuery({
        queryKey: ['getClientCaseBookmark'],
        queryFn: async () => {
            const { data } = await fetchCaseBookmark();
            return data;
        },
    });

    return (
        <>
            <Card className={styles.widget}>
                <h2>Dossiers favoris</h2>
                {isPending ? (
                    <LoaderDot />
                ) : (
                    <div className={styles.containerFav}>
                        {data?.map((item: CaseType) => (
                            <a
                                href={clientCasesIdPage.path + item.id}
                                key={item.id}
                                className={styles.fav}
                            >
                                <FaIcon>fa-folder</FaIcon>
                                <small>{item.caseName || item.caseRef}</small>
                                <span className={styles.favName}>
                                    {item.user}
                                </span>
                            </a>
                        ))}
                    </div>
                )}
            </Card>
        </>
    );
}

export default ClientsCasesBookmarkWidget;
