import ButtonLink from '../../../components/ButtonLink';
import FaIcon from '../../../components/Icons/FaIcon';
import { lawfirmsIdPage } from '../../../constants/pagesRoutesContants';
import LawfirmType from '../../../types/LawfirmType';
import styles from './style.module.scss';

interface Props {
    lawfirm: LawfirmType;
}

function LawfirmCard(props: Props) {
    const { lawfirm } = props;
    return (
        <div key={lawfirm.id} className={styles.cardContainer} id={lawfirm.id}>
            <div className={styles.coverCard}>
                <span className={styles.name}>{lawfirm.name}</span>
            </div>
            <div className={styles.cardBody}>
                <div className={styles.cardBodyInfo}>
                    <span>
                        <FaIcon>fa-map-marker</FaIcon>
                        {lawfirm.address}
                    </span>
                    <span>
                        <FaIcon>fa-phone</FaIcon>
                        {lawfirm.phone?.length > 0 ? lawfirm.phone : 'NC'}
                    </span>
                    <span>
                        <FaIcon>fa-envelope </FaIcon>
                        {lawfirm.email?.length > 0 ? lawfirm.email : 'NC'}
                    </span>
                </div>
            </div>
            <div className={styles.cardFooter}>
                <ButtonLink
                    link={lawfirmsIdPage.path + lawfirm.id}
                    variant="secondary"
                >
                    Voir
                </ButtonLink>
            </div>
        </div>
    );
}

export default LawfirmCard;
