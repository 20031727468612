import { userEndpoint as endpoint } from '../constants/endpointsContants';
import UserType from '../types/UserType';
import request from './genericRequestService';

async function fetchUser() {
    return await request.get({ endpoint: endpoint + '/me' });
}
async function fetchUserById(id: string) {
    return await request.get({ endpoint: endpoint, id: id });
}
async function updateUser(data: UserType) {
    return await request.put({ endpoint: endpoint + '/me', data });
}

export { fetchUser, fetchUserById, updateUser };
