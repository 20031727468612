import { classNames } from '../../../helpers/classNames';
import Button from '../../Button';
import FaIcon from '../../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    folderCount: number;
    folderName: string;
}

function FileExplorerFooter(props: Props) {
    const { folderCount, folderName } = props;
    return (
        <div className={classNames([styles.feBar])}>
            <span>
                <FaIcon>fa-folder-open</FaIcon>
                {folderName}
            </span>
            <span>-</span>
            <span>
                {folderCount} file{folderCount > 1 && 's'}
            </span>
            <Button>
                <FaIcon>fa-list</FaIcon>
            </Button>
            <Button>
                <FaIcon>fa-th</FaIcon>
            </Button>
        </div>
    );
}

export default FileExplorerFooter;
