import { contactEndpoint as endpoint } from '../constants/endpointsContants';
import request from './genericRequestService';

async function fetchContact() {
    return await request.get({ endpoint });
}
async function fetchContactBookmark() {
    return await request.get({ endpoint: endpoint + '/bookmark' });
}
async function postContactBookmark(id: string) {
    return await request.post({ endpoint: endpoint + '/bookmark', id });
}

export { fetchContact, fetchContactBookmark, postContactBookmark };
