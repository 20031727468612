import { classNames } from '../../../helpers/classNames';
import FilterElement from '../filter-element';
import styles from './style.module.scss';

function FileFilter() {
    return (
        <div className={classNames([styles.feBar])}>
            <FilterElement sort="ascending">Name</FilterElement>|
            <FilterElement>Format</FilterElement>|
            <FilterElement>Date</FilterElement>|
            <FilterElement>Size</FilterElement>
        </div>
    );
}

export default FileFilter;
