import { widgets } from '../../constants/widgetsContants';
import { WidgetName } from '../../enums/WidgetNameEnum';
interface Props {
    name: WidgetName;
}
function Widgets(props: Props) {
    const { name } = props;

    if (!Object.keys(widgets).includes(name.toString())) {
        return <></>;
    }

    const Widget = widgets[name];
    return <Widget />;
}

export default Widgets;
