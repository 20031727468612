import Input from '../../Input';
import styles from './style.module.scss';

interface Props {
    name: string;
    label: string;
    value?: string;
    onChange?: (param: any) => any;
}

function DateForm(props: Props) {
    const { name, label, value, onChange } = props;
    return (
        <div className={styles.divInput}>
            <Input
                label={label}
                name={name}
                type="date"
                onChange={onChange}
                value={value}
            />
        </div>
    );
}

export default DateForm;
