import Card from '../../Card';
import Timer from '../../Timer';
import styles from './style.module.scss';
function TimerWidget() {
    const startDate = new Date('2014/04/17 06:00:00');
    const endDate = new Date('2014/04/17 07:00:00');
    return (
        <Card className={styles.widget}>
            <Timer startDate={startDate} endDate={endDate} />
        </Card>
    );
}

export default TimerWidget;
