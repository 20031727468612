import { ChangeEvent, useState } from 'react';
import { navigationHistory, root } from '../../constants/file-explorerContants';
import { classNames } from '../../helpers/classNames';
import { default as File, default as FileType } from '../../types/FileType';
import FileExplorerBody from './file-explorer-body';
import FileExplorerFooter from './file-explorer-footer';
import FileExplorerNavbar from './file-explorer-navbar';
import FileFilter from './file-filter';
import styles from './style.module.scss';

function FileExplorer() {
    const data: File[] = [
        { Id: '0', ParentId: '', Extension: 'txt', Name: 'txt file' },
        { Id: '1', ParentId: '', Extension: 'png', Name: 'png file' },
        { Id: '2', ParentId: '', Extension: 'jpeg', Name: 'jpeg file' },
        { Id: '3', ParentId: '', Extension: 'flac', Name: 'flac file' },
        { Id: '4', ParentId: '', Extension: 'mp3', Name: 'mp3 file' },
        { Id: '5', ParentId: '24', Extension: 'wav', Name: 'wav file' },
        { Id: '6', ParentId: '24', Extension: 'wma', Name: 'wma file' },
        { Id: '7', ParentId: '24', Extension: 'aac', Name: 'aa file' },
        { Id: '8', ParentId: '24', Extension: 'mpeg', Name: 'mpeg file' },
        { Id: '9', ParentId: '24', Extension: 'avi', Name: 'avi file' },
        { Id: '10', ParentId: '24', Extension: 'flv', Name: 'flv file' },
        { Id: '11', ParentId: '24', Extension: 'mp4', Name: 'mp4 file' },
        { Id: '12', ParentId: '24', Extension: 'zip', Name: 'zip file' },
        { Id: '13', ParentId: '24', Extension: 'rar', Name: 'rar file' },
        { Id: '14', ParentId: '24', Extension: '7z', Name: '7z file' },
        { Id: '15', ParentId: '24', Extension: 'tar', Name: 'tar file' },
        { Id: '16', ParentId: '24', Extension: 'csv', Name: 'csv file' },
        { Id: '17', ParentId: '24', Extension: 'xlsx', Name: 'xlsx file' },
        { Id: '18', ParentId: '24', Extension: 'xls', Name: 'xls file' },
        { Id: '19', ParentId: '24', Extension: 'pdf', Name: 'pdf file' },
        { Id: '20', ParentId: '', Extension: 'ppt', Name: 'ppt file' },
        { Id: '21', ParentId: '', Extension: 'pptx', Name: 'pptx file' },
        { Id: '22', ParentId: '', Extension: 'doc', Name: 'doc file' },
        { Id: '23', ParentId: '', Extension: 'docx', Name: 'docx file' },
        { Id: '24', ParentId: '', Extension: 'folder', Name: 'folder 1' },
        { Id: '25', ParentId: '', Extension: 'odt', Name: 'odt file' },
        { Id: '26', ParentId: '', Extension: 'txt', Name: 'txt file' },
        { Id: '27', ParentId: '', Extension: 'csv', Name: 'csv file' },
        { Id: '28', ParentId: '35', Extension: 'xlsx', Name: 'xlsx file' },
        { Id: '29', ParentId: '35', Extension: 'xls', Name: 'xls file' },
        { Id: '30', ParentId: '35', Extension: 'pdf', Name: 'pdf file' },
        { Id: '31', ParentId: '35', Extension: 'ppt', Name: 'ppt file' },
        { Id: '32', ParentId: '35', Extension: 'pptx', Name: 'pptx file' },
        { Id: '33', ParentId: '35', Extension: 'doc', Name: 'doc file' },
        { Id: '34', ParentId: '35', Extension: 'docx', Name: 'docx file' },
        { Id: '35', ParentId: '', Extension: 'folder', Name: 'folder 2' },
        { Id: '36', ParentId: '', Extension: 'odt', Name: 'odt file' },
        { Id: '37', ParentId: '', Extension: 'txt', Name: 'txt file' },
        { Id: '38', ParentId: '', Extension: 'csv', Name: 'csv file' },
        { Id: '39', ParentId: '', Extension: 'xlsx', Name: 'xlsx file' },
        { Id: '41', ParentId: '', Extension: 'xls', Name: 'xls file' },
        { Id: '42', ParentId: '', Extension: 'pdf', Name: 'pdf file' },
        { Id: '43', ParentId: '', Extension: 'ppt', Name: 'ppt file' },
        { Id: '44', ParentId: '', Extension: 'pptx', Name: 'pptx file' },
        { Id: '45', ParentId: '', Extension: 'doc', Name: 'doc file' },
        { Id: '46', ParentId: '', Extension: 'docx', Name: 'docx file' },
        { Id: '47', ParentId: '35', Extension: 'folder', Name: 'folder 3' },
        { Id: '48', ParentId: '', Extension: 'odt', Name: 'odt file' },
        { Id: '49', ParentId: '', Extension: 'txt', Name: 'txt file' },
        { Id: '50', ParentId: '58', Extension: 'csv', Name: 'csv file' },
        { Id: '51', ParentId: '58', Extension: 'xlsx', Name: 'xlsx file' },
        { Id: '52', ParentId: '58', Extension: 'xls', Name: 'xls file' },
        { Id: '53', ParentId: '58', Extension: 'pdf', Name: 'pdf file' },
        { Id: '54', ParentId: '', Extension: 'ppt', Name: 'ppt file' },
        { Id: '55', ParentId: '47', Extension: 'pptx', Name: 'pptx file' },
        { Id: '56', ParentId: '47', Extension: 'doc', Name: 'doc file' },
        { Id: '57', ParentId: '47', Extension: 'docx', Name: 'docx file' },
        { Id: '58', ParentId: '47', Extension: 'folder', Name: 'folder 4' },
        { Id: '59', ParentId: '47', Extension: 'odt', Name: 'odt file' },
        { Id: '60', ParentId: '47', Extension: 'txt', Name: 'txt file' },
        { Id: '61', ParentId: '47', Extension: 'csv', Name: 'csv file' },
        { Id: '62', ParentId: '47', Extension: 'xlsx', Name: 'xlsx file' },
        { Id: '63', ParentId: '47', Extension: 'xls', Name: 'xls file' },
        { Id: '64', ParentId: '47', Extension: 'pdf', Name: 'pdf file' },
        { Id: '65', ParentId: '47', Extension: 'ppt', Name: 'ppt file' },
        { Id: '66', ParentId: '', Extension: 'pptx', Name: 'pptx file' },
        { Id: '67', ParentId: '', Extension: 'doc', Name: 'doc file' },
        { Id: '68', ParentId: '', Extension: 'docx', Name: 'docx file' },
        { Id: '69', ParentId: '', Extension: 'folder', Name: 'folder 5' },
        { Id: '70', ParentId: '69', Extension: 'odt', Name: 'odt file' },
    ];

    const [folder, setFolder] = useState<FileType>(root);
    const [folderCount, setFolderCount] = useState<number>(0);

    const [folderFiles, setFolderFiles] = useState(
        getFolderFiles(data, folder)
    );
    const setFolderData = (
        newFolder: FileType,
        setNavigationHistory: boolean = false
    ) => {
        setFolder(newFolder);
        setFolderFiles(getFolderFiles(data, newFolder));
        if (setNavigationHistory) {
            const indexFolder = navigationHistory.indexOf(folder);
            navigationHistory.splice(indexFolder + 1);
            navigationHistory.push(newFolder);
        }
    };

    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFolderFiles(getFolderFiles(data, folder, value));
    };

    return (
        <div className={classNames([styles.fe])}>
            <FileExplorerNavbar
                setFolder={setFolderData}
                folder={folder}
                navigationHistory={navigationHistory}
                onSearchInputChange={handleSearchInputChange}
            />
            <FileFilter />
            <FileExplorerBody
                files={folderFiles}
                setFolder={setFolderData}
                setFolderCount={setFolderCount}
            ></FileExplorerBody>
            <FileExplorerFooter
                folderCount={folderCount}
                folderName={folder.Name}
            />
        </div>
    );
}

function getFolderFiles(
    data: FileType[],
    folder: FileType,
    searchValue: string = ''
): FileType[] {
    const files = data.filter((file: FileType) => {
        return file.ParentId === folder.Id && file.Name.includes(searchValue);
    });

    return files;
}

// search in all the folders
// function searchFiles(data:FileType[], searchValue:string):FileType[] {
//   const files = data.filter((file: FileType) => {
//     return file.Name.includes(searchValue);
//   });
//   return files;
// }

export default FileExplorer;
