import { ChangeEvent } from 'react';
import { classNames } from '../../../helpers/classNames';
import FileType from '../../../types/FileType';
import Button from '../../Button';
import FaIcon from '../../Icons/FaIcon';
import SearchInput from '../../SearchInput';
import styles from './style.module.scss';

interface Props {
    folderName?: string;
    folder: FileType;
    setFolder: (folder: FileType) => void;
    navigationHistory: FileType[];
    onSearchInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

function FileExplorerNavbar(props: Props) {
    const {
        folderName,
        folder,
        setFolder,
        navigationHistory,
        onSearchInputChange,
    } = props;

    const indexPage = navigationHistory.indexOf(folder);
    const handleClickPrevious = () => {
        const indexLastPage = navigationHistory.indexOf(folder) - 1;
        const lastPage = navigationHistory[indexLastPage];
        if (lastPage) {
            setFolder(lastPage);
        }
    };
    const handleClickNext = () => {
        const indexNextPage = navigationHistory.indexOf(folder) + 1;
        const nextPage = navigationHistory[indexNextPage];
        if (nextPage) {
            setFolder(nextPage);
        }
    };
    const handleClickRefresh = () => {
        setFolder(folder);
    };

    return (
        <div className={classNames([styles.feBar])}>
            <Button onClick={handleClickPrevious} isDisabled={indexPage === 0}>
                <FaIcon>fa-chevron-left</FaIcon>
            </Button>
            <Button
                onClick={handleClickNext}
                isDisabled={indexPage === navigationHistory.length - 1}
            >
                <FaIcon>fa-chevron-right</FaIcon>
            </Button>
            <Button onClick={handleClickRefresh}>
                <FaIcon>fa-refresh</FaIcon>
            </Button>
            <span>{folderName}</span>
            <SearchInput onChange={onSearchInputChange} />
        </div>
    );
}

export default FileExplorerNavbar;
