import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAlert } from '../../../../AlertProvider';
import Button from '../../../../components/Button';
import AddressForm from '../../../../components/FormEditor/AddressForm';
import DateForm from '../../../../components/FormEditor/DateForm';
import IdentityForm from '../../../../components/FormEditor/IdentityForm';
import PhoneForm from '../../../../components/FormEditor/PhoneForm';
import { alertType } from '../../../../constants/alertTypeConstant';
import { successStatus } from '../../../../constants/requestStatusContants';
import { updateUser } from '../../../../services/userService';
import UserType from '../../../../types/UserType';
import styles from './style.module.scss';

interface Props {
    user: UserType | null;
}

function PersonalInformationSettings(props: Props) {
    const { user } = props;
    const alert = useAlert();

    const [userData, setUserData] = useState<UserType | null>();

    useEffect(() => {
        setUserData(user);
    }, [user]);

    const { mutate: saveUser } = useMutation({
        mutationFn: async () => {
            if (userData) {
                const { data, status } = await updateUser(userData);

                if (successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Vos modifications ont été enregistrées avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (userData) {
            const data: { [key: string]: any } = { ...userData };
            Object.keys(userData).forEach((key: string) => {
                if (key === name) {
                    data[name] = value;
                }
            });
            setUserData(data as UserType);
        }
    };

    const handleSave = () => {
        saveUser();
    };

    return (
        <div>
            <IdentityForm data={userData} onChange={handleChange} />
            <DateForm
                onChange={handleChange}
                name={'birthDate'}
                label="Date de naissance"
                value={userData?.birthDate?.toString()}
            />
            <PhoneForm value={userData?.phone} onChange={handleChange} />
            <AddressForm data={userData} onChange={handleChange} />
            <div className={styles.btn}>
                <Button onClick={handleSave}>Enregistrer</Button>
            </div>
        </div>
    );
}

export default PersonalInformationSettings;
