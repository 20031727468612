import CalendarWidget from '../components/Widgets/CalendarWidget';
import ClientsCasesBookmarkWidget from '../components/Widgets/ClientsCasesBookmarkWidget';
import ClientsCasesGraphWidget from '../components/Widgets/ClientsCasesGraphWidget';
import ContactBookmarkWidget from '../components/Widgets/ContactBookmarkWidget';
import NewClientsCasesWidget from '../components/Widgets/NewClientsCasesWidget';
import NewMessagesWidget from '../components/Widgets/NewMessagesWidget';
import NextMeetingsWidget from '../components/Widgets/NextMeetingsWidget';
import NextWeekJudicialCalendarWidget from '../components/Widgets/NextWeekJudicialCalendarWidget';
import SpecialitiesGraphWidget from '../components/Widgets/SpecialitiesGraphWidget';
import StopwatchWidget from '../components/Widgets/StopwatchWidget';
import TimerWidget from '../components/Widgets/TimerWidget';
import TodayJudicialCalendarWidget from '../components/Widgets/TodayJudicialCalendarWidget';

export const widgets: { [key: string]: any } = {
    ClientsCasesGraphWidget: ClientsCasesGraphWidget,
    SpecialitiesGraphWidget: SpecialitiesGraphWidget,
    NextMeetingsWidget: NextMeetingsWidget,
    CalendarWidget: CalendarWidget,
    NewMessagesWidget: NewMessagesWidget,
    ContactBookmarkWidget: ContactBookmarkWidget,
    NewClientsCasesWidget: NewClientsCasesWidget,
    ClientsCasesBookmarkWidget: ClientsCasesBookmarkWidget,
    NextWeekJudicialCalendarWidget: NextWeekJudicialCalendarWidget,
    TodayJudicialCalendarWidget: TodayJudicialCalendarWidget,
    StopwatchWidget: StopwatchWidget,
    TimerWidget: TimerWidget,
};
