import { ChangeEvent, useEffect, useState } from 'react';
import OptionInterface from '../../interfaces/OptionInterface';
import styles from './style.module.scss';

interface Props {
    name: string;
    label?: string;
    options: OptionInterface[];
    checked?: OptionInterface[];
    setChecked?: (options: OptionInterface[]) => void;
    onChange?: (
        event: ChangeEvent<HTMLInputElement>,
        options: OptionInterface[]
    ) => void;
    isDisabled?: boolean;
}

function Checkbox(props: Props) {
    const {
        name,
        label,
        options = [],
        checked,
        onChange,
        isDisabled,
        setChecked,
    } = props;
    const [listChecked, setListChecked] = useState<OptionInterface[]>([]);

    useEffect(() => {
        if (checked) {
            setListChecked(checked);
        }
    }, [checked]);

    const listCheckedString = listChecked.map((c) => {
        return c?.name;
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        let selectedItems = [...listChecked];

        if (listCheckedString.includes(value)) {
            selectedItems = listChecked.filter((x) => {
                return x?.name !== value;
            });
        } else {
            const newSelectedOption = options.find((o) => {
                return o.name === value;
            });
            if (newSelectedOption) {
                selectedItems.push(newSelectedOption);
            }
        }

        if (onChange) {
            onChange(event, selectedItems);
        } else {
            setListChecked(selectedItems);
        }
        if (setChecked) {
            setChecked(selectedItems);
        }
    };

    return (
        <div className={styles.container}>
            <div>
                <label>{label}</label>
            </div>
            <div className={styles.optionContainer}>
                {options.map((option) => (
                    <div key={option.name} className={styles.inputContainer}>
                        <input
                            type="checkbox"
                            name={name}
                            value={option.name}
                            onChange={handleChange}
                            checked={listCheckedString.includes(option.name)}
                            disabled={isDisabled}
                            className={styles.input}
                        />
                        <label htmlFor={option.name}>{option.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Checkbox;
