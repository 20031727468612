import { serviceEndpoint as endpoint } from '../constants/endpointsContants';
import ServiceType from '../types/ServiceType';
import request from './genericRequestService';

async function fetchServices() {
    return await request.get({ endpoint });
}
async function addService(data: ServiceType) {
    return await request.post({ endpoint, data });
}
async function deleteService(id: string) {
    return await request.delete({ endpoint, id });
}
async function updateService(data: ServiceType) {
    return await request.put({ endpoint, id: data.id, data });
}

export { addService, deleteService, fetchServices, updateService };
