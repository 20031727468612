import { useEffect, useState } from 'react';
import { classNames } from '../../helpers/classNames';
import { GetTimeGap, timeGapToString } from '../../helpers/date';
import Button from '../Button';
import styles from './style.module.scss';

interface Props {
    startDate: Date;
    endDate: Date;
    isMini?: boolean;
}

function Timer(props: Props) {
    const { startDate, endDate, isMini = false } = props;
    const initialTimegap = GetTimeGap(startDate, endDate);
    const [timeLeft, setTimeLeft] = useState(initialTimegap);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let interval: any = null;

        if (isRunning) {
            interval = setInterval(() => {
                if (timeLeft > 0) {
                    setTimeLeft((prevTime) => prevTime - 1000);
                } else {
                    setIsRunning(false);
                }
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isRunning, timeLeft]);

    const handleClickStart = () => {
        setIsRunning(!isRunning);
    };
    const handleClickReset = () => {
        setIsRunning(false);
        setTimeLeft(initialTimegap);
    };

    return (
        <div
            className={classNames([
                styles.timer,
                isMini && isRunning ? styles.mini : '',
            ])}
        >
            <div className={styles.timeLeft}>
                <span className={styles.title}>Minuteur</span>
                <span className={styles.time}>{timeGapToString(timeLeft)}</span>
            </div>
            <div className={styles.actionTimer}>
                <div className={styles.resetTimer}>
                    <Button
                        variant="primaryLightBordered"
                        onClick={handleClickReset}
                    >
                        Reset
                    </Button>
                </div>
                <div className={styles.starStop}>
                    <Button
                        variant={
                            isRunning ? 'secondaryLightBordered' : 'secondary'
                        }
                        onClick={handleClickStart}
                    >
                        {isRunning ? 'Stop' : 'Start'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Timer;
