import { requestStatus } from './requestStatusContants';

export const alertType: { [key: string]: 'success' | 'error' } = {
    [requestStatus.OK]: 'success',
    [requestStatus.Created]: 'success',
    [requestStatus.Accepted]: 'success',
    [requestStatus.BadRequest]: 'error',
    [requestStatus.Unauthorized]: 'error',
    [requestStatus.Forbidden]: 'error',
    [requestStatus.NotFound]: 'error',
    [requestStatus.MethodNotAllowed]: 'error',
    [requestStatus.RequestTimeout]: 'error',
    [requestStatus.UnsupportedMediaType]: 'error',
    [requestStatus.InternalServerError]: 'error',
    [requestStatus.NotImplemented]: 'error',
    [requestStatus.BadGateway]: 'error',
    [requestStatus.ServiceUnavailable]: 'error',
    [requestStatus.GatewayTimeout]: 'error',
};
