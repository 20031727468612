import { useEffect, useState } from 'react';
import { useAlert } from '../../AlertProvider';
import styles from './style.module.scss';

function Alert() {
    const {
        isVisible: isAlertVisible,
        data: alertData,
        hide: hideAlert,
    } = useAlert();
    const { children, duration = 10, type } = alertData;
    const notificationStyle: { [key: string]: string } = {
        error: styles.error,
        success: styles.success,
        pending: styles.pending,
    };

    const [timeRemaining, setTimeRemaining] = useState(duration);

    useEffect(() => {
        let interval: any | null = null;

        if (isAlertVisible) {
            interval = setInterval(() => {
                if (timeRemaining > 0) {
                    setTimeRemaining((prevTime) => prevTime - 1);
                } else {
                    hideAlert();
                }
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isAlertVisible, hideAlert, timeRemaining]);

    return (
        <div className={styles.container}>
            {isAlertVisible && (
                <div
                    className={`${styles.notification} ${type ? notificationStyle[type] : ''}`}
                >
                    {children}
                </div>
            )}
        </div>
    );
}

export default Alert;
