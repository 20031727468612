export function AddMinutes(date: Date, minutes: number) {
    return new Date(date.getTime() + minutes * 60 * 1000);
}

export function GetTimeGap(startDate: Date, endDate: Date) {
    const timGap = endDate.getTime() - startDate.getTime();
    return timGap;
}
export function GetTimeGapString(startDate: Date, endDate: Date) {
    const timGap = new Date(endDate).getTime() - new Date(startDate).getTime();
    return timeGapToString(timGap);
}

export function timeGapToString(timGap: number) {
    const days = Math.floor(timGap / (1000 * 60 * 60 * 24));
    timGap -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(timGap / (1000 * 60 * 60));
    timGap -= hours * (1000 * 60 * 60);

    const mins = Math.floor(timGap / (1000 * 60));
    timGap -= mins * (1000 * 60);

    const seconds = Math.floor(timGap / 1000);
    timGap -= seconds * 1000;

    const stringValue =
        days > 0
            ? `${days} jour${days > 1 ? 's' : ''}`
            : '' +
              `${hours < 10 ? '0' : ''}${hours}:` +
              `${mins < 10 ? '0' : ''}${mins}:` +
              `${seconds < 10 ? '0' : ''}${seconds}`;

    return stringValue;
}
