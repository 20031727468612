import React from 'react';
import Button from '../Button';
import ButtonLink from '../ButtonLink';

interface Props {
    children: React.ReactNode;
    btnClassName?: any;
    onMouseDown?: (param: any) => void;
    onClick?: (param: any) => void;
    variant?:
        | 'primary'
        | 'secondary'
        | 'primaryLight'
        | 'secondaryLight'
        | 'primaryLightBordered'
        | 'secondaryLightBordered'
        | 'tertiary';
    size?: string;
    type?: 'button' | 'submit' | 'reset';
    isDisabled?: boolean;
    icon?: string;
    name?: string;
    link?: string;
}

function ButtonVariant(props: Props) {
    const {
        link,
        btnClassName,
        variant = 'primary',
        size,
        type,
        onClick,
        onMouseDown,
        isDisabled,
        children,
        icon,
        name,
    } = props;
    return (
        <>
            {link ? (
                <ButtonLink
                    btnClassName={btnClassName}
                    onClick={onClick}
                    variant={variant}
                    size={size}
                    type={type}
                    icon={icon}
                    link={link}
                >
                    {children}
                </ButtonLink>
            ) : (
                <Button
                    btnClassName={btnClassName}
                    onMouseDown={onMouseDown}
                    onClick={onClick}
                    variant={variant}
                    size={size}
                    type={type}
                    isDisabled={isDisabled}
                    icon={icon}
                    name={name}
                >
                    {children}
                </Button>
            )}
        </>
    );
}

export default ButtonVariant;
