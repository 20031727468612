import { useQuery } from '@tanstack/react-query';
import { contactIdPage } from '../../../constants/pagesRoutesContants';
import { fetchContactBookmark } from '../../../services/contactService';
import UserConversationType from '../../../types/UserConversationType';
import Card from '../../Card';
import FaIcon from '../../Icons/FaIcon';
import LoaderDot from '../../LoaderDot';
import styles from './style.module.scss';
function ContactBookmarkWidget() {
    const { data, isPending } = useQuery({
        queryKey: ['getContactBookmark'],
        queryFn: async () => {
            const { data } = await fetchContactBookmark();
            return data;
        },
    });

    if (isPending) {
        return <LoaderDot />;
    }

    return (
        <>
            <Card className={styles.widget}>
                <h2>Contacts favoris</h2>
                {isPending ? (
                    <LoaderDot />
                ) : (
                    <div className={styles.containerFav}>
                        {data?.map((item: UserConversationType) => (
                            <a
                                href={contactIdPage.path + item.id}
                                key={item.id}
                                className={styles.fav}
                            >
                                <FaIcon>fa-envelope</FaIcon>
                                <span className={styles.favName}>
                                    {item.name}
                                </span>
                            </a>
                        ))}
                    </div>
                )}
            </Card>
        </>
    );
}

export default ContactBookmarkWidget;
