export const formats = {
    image: ['png', 'jpeg'],
    audio: ['flac', 'mp3', 'wav', 'wma', 'aac'],
    video: ['mpeg', 'avi', 'flv', 'mp4'],
    archive: ['zip', 'rar', '7z', 'tar'],
    excel: ['csv', 'xlsx', 'xls'],
    pdf: ['pdf'],
    powerpoint: ['ppt', 'pptx'],
    word: ['docx', 'doc', 'odt'],
    folder: ['folder'],
    folderOpen: ['folderOpen'],
    text: ['txt'],
};
