import { holidaysEndpoint as endpoint } from '../constants/endpointsContants';
import HolidaysType from '../types/HolidaysType';
import request from './genericRequestService';

async function getHolidays(holidays: HolidaysType) {
    return await request.get({ endpoint });
}
async function postHolidays(holidays: HolidaysType) {
    return await request.post({ endpoint, data: holidays });
}

async function updateHolidays(holidays: HolidaysType) {
    return await request.put({ endpoint, data: holidays });
}

export { getHolidays, postHolidays, updateHolidays };
