import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAlert } from '../../../../AlertProvider';
import Button from '../../../../components/Button';
import IdentityForm from '../../../../components/FormEditor/IdentityForm';
import PhoneForm from '../../../../components/FormEditor/PhoneForm';
import Loader from '../../../../components/Loader';
import Textaera from '../../../../components/Textaera';
import { alertType } from '../../../../constants/alertTypeConstant';
import { successStatus } from '../../../../constants/requestStatusContants';
import { updateLawyer } from '../../../../services/lawyerService';
import LawyerType from '../../../../types/LawyerType';
import UpdateLawyerType from '../../../../types/UpdateLawyerType';
import LawyerBar from '../lawyerBar';
import SpecialitiesSettings from '../specialitiesSettings';
import styles from './style.module.scss';

interface Props {
    lawyer: LawyerType | null;
}

function ProfessionalInformationSettings(props: Props) {
    const { lawyer } = props;
    const alert = useAlert();

    const [lawyerData, setLawyerData] = useState<UpdateLawyerType | null>();

    useEffect(() => {
        if (lawyer) {
            setLawyerData({
                id: lawyer.id,
                lastName: lawyer.lastName,
                firstName: lawyer.firstName,
                address: lawyer.address,
                streetName: lawyer.streetName,
                city: lawyer.city,
                zipCode: lawyer.zipCode,
                country: lawyer.country,
                phone: lawyer.phone,
                photo: lawyer.photo,
                cover: lawyer.cover,
                description: lawyer.description,
            });
        }
    }, [lawyer]);

    const { mutate: saveLawyer } = useMutation({
        mutationFn: async () => {
            if (lawyerData) {
                const { data, status } = await updateLawyer(lawyerData);

                if (successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Vos modifications ont été enregistrées avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (lawyerData) {
            const data: { [key: string]: any } = { ...lawyerData };
            Object.keys(lawyerData).forEach((key: string) => {
                if (key === name) {
                    data[name] = value;
                }
            });
            setLawyerData(data as UpdateLawyerType);
        }
    };

    if (!lawyer) {
        return <Loader />;
    }

    return (
        <div>
            <IdentityForm data={lawyerData} onChange={handleChange} />
            <PhoneForm value={lawyerData?.phone} onChange={handleChange} />
            <Textaera
                label="Présentation"
                name="description"
                onChange={handleChange}
                value={lawyerData?.description}
            />
            <div className={styles.btn}>
                <Button onClick={saveLawyer}>Enregistrer</Button>
            </div>
            <SpecialitiesSettings specialities={lawyer?.specialities} />
            <LawyerBar lawyerBarId={lawyer?.lawyerBarId} />
        </div>
    );
}

export default ProfessionalInformationSettings;
