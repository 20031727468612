import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import userPhoto from '../../../assets/user.jpg';
import ButtonLink from '../../../components/ButtonLink';
import Card from '../../../components/Card';
import FaIcon from '../../../components/Icons/FaIcon';
import { lawyersIdPage } from '../../../constants/pagesRoutesContants';
import { classNames } from '../../../helpers/classNames';
import { fetchLawfirm } from '../../../services/lawfirmService';
import LawfirmType from '../../../types/LawfirmType';
import Lawfirms from '../page';
import styles from './style.module.scss';

function Lawfirm() {
    const params = useParams();

    const { isPending, error, data } = useQuery({
        queryKey: ['lawfirm', params.lawfirmId],
        queryFn: async () => {
            if (!params.lawfirmId) {
                return <Lawfirms />;
            }
            const { data } = await fetchLawfirm(params.lawfirmId);
            return data;
        },
    });

    const lawfirm: LawfirmType = data;
    if (isPending) return <div>Loading...</div>;

    if (error) return <div>An error has occurred: {error.message}</div>;

    const style: { [k: string]: any } = {};
    if (lawfirm.cover) {
        style.backgroundImage = `url(${lawfirm.cover})`;
    }

    const onImageError = (event: any) => {
        event.target.style = 'display:none';
    };

    return (
        <div className={classNames([styles.lawfirmDetails])}>
            <div className={styles.cover} style={style}>
                <div className={styles.photoContainer}>
                    <div className={styles.bodyId}>{lawfirm.name}</div>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.bodyInfo}>
                    <div className={styles.bodyContact}>
                        <div>
                            <span className={styles.icon}>
                                <FaIcon>fa-map-location-dot</FaIcon>
                            </span>
                            <span>Adresse:</span>
                            <span>
                                {lawfirm.buildingNumber} {lawfirm.streetName}
                            </span>
                            <span>
                                {lawfirm.city} {lawfirm.zipCode}{' '}
                                {lawfirm.country}
                            </span>
                        </div>
                        <div>
                            <span className={styles.icon}>
                                <FaIcon>fa-phone</FaIcon>
                            </span>
                            <span>Téléphone:</span>
                            <span>
                                {lawfirm.phone?.length > 0
                                    ? lawfirm.phone
                                    : 'NC'}
                            </span>
                        </div>
                        <div>
                            <span className={styles.icon}>
                                <FaIcon>fa-envelope</FaIcon>
                            </span>
                            <span>Mail:</span>
                            <span>
                                {lawfirm.email?.length > 0
                                    ? lawfirm.email
                                    : 'NC'}
                            </span>
                        </div>
                    </div>
                    <Card className={styles.bodyDescription}>
                        <h3>Présentation : </h3>
                        <p>
                            {lawfirm.description || lawfirm.lawyers.length > 1
                                ? `Le cabinet ${lawfirm.name}, situé à ${lawfirm.address}, regroupe plusieurs avocats spécialisés dans divers domaines du droit. Chaque membre de l'équipe est dédié à accompagner les clients dans leurs démarches juridiques et à leur fournir des conseils adaptés à leurs besoins.`
                                : `Situé à ${lawfirm.address}, le cabinet ${lawfirm.name} propose des services juridiques dans divers domaines du droit. Maitre ${lawfirm.lawyers[0].lastName} accompagne ses clients dans leurs démarches juridiques, en leur offrant conseils et représentation adaptés à leurs besoins.`}
                        </p>
                    </Card>
                    {lawfirm.lawyers.length > 0 && (
                        <Card className={styles.bodyLawyers}>
                            <h3>Nos Avocats : </h3>
                            <div className={styles.lawyerList}>
                                {lawfirm.lawyers.map((lawyer) => (
                                    <ButtonLink
                                        link={lawyersIdPage.path + lawyer.id}
                                        key={lawyer.id}
                                        variant={'secondaryLight'}
                                    >
                                        <div className={styles.lawyer}>
                                            <div className={styles.photoCard}>
                                                <img
                                                    src={
                                                        lawyer.photo ||
                                                        userPhoto
                                                    }
                                                    alt={`${
                                                        lawyer.firstName
                                                    } ${lawyer.lastName?.toLocaleUpperCase()}`}
                                                    onError={onImageError}
                                                    className={styles.photo}
                                                />
                                            </div>
                                            <span>
                                                {`${
                                                    lawyer.firstName
                                                } ${lawyer.lastName?.toLocaleUpperCase()}`}
                                            </span>
                                        </div>
                                    </ButtonLink>
                                ))}
                            </div>
                        </Card>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Lawfirm;
